.vehicle {
    @include flex(row, center, flex-start);
    width: 100%;
    background-color: $color-background-inverse;
    min-height: 63px;
    border-radius: $border-radius-base;
    border: 1px solid $color-border-dark;
    color: $color-text-inverse;
    padding: 0 $space-md;
    margin-bottom: $space-lg;

    &--sticky {
        position: fixed;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        width: 1280px;
        z-index: 25;
    }

    &__icon {
        margin-right: $space-lg;
        width: 50px;
        height: 50px;
        color: #fff;
    }

    &__name {
        margin-right: $space-lg;
        font-weight: 500;
    }

    &__count {
        padding: $space-xs 0 $space-xs $space-lg;
        border-left: 1px solid $color-border-inverse;
    }

    &__filter {
        margin-left: auto;
        height: 32px;
        padding: 0 $space-xs;
        border-radius: $border-radius-base;
        background-color: $color-background;
        border: 1px solid $color-border;
        cursor: pointer;
        @include flex(row, center, center);

        .icon {
            margin-right: $space-xxs;
        }

        &:hover {
            background-color: $color-background-dark;
        }
    }
}
