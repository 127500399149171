.loader {
  position: absolute;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border-radius: 100%;
  padding: 6px;
  box-shadow: 0 0 10px -4px #00000069;
  background-color: $color-background;
  cursor: progress;

  &--fixed {
    position: fixed;
  }

  &--input-fixed {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 85%;
    height: 25px;
    width: 25px;
  }

  &__backdrop {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: progress;
  }

  &__icon {
    border: 4px solid $color-primary-light;
    border-top: 4px solid $color-background;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    -webkit-animation: spin 0.7s linear infinite;
    animation: spin 0.7s linear infinite;

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.loader-linear {
  overflow: hidden;
  width: 100%;
  height: 4px;
  background-color: lighten($color-primary-light, 45);

  &--absolute {
    position: absolute;
  }
}

.loader-linear-inner {
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: $color-primary-light;
    animation: linear-first 1.5s infinite ease-out;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: $color-primary-light;
    animation: linear-second 1.5s infinite ease-in;
  }
}

@keyframes linear-first {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes linear-second {
  0% {
    left: -150%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}
