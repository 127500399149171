.search-header {
  @include flex(row, normal, space-between);

  .field {
    &__start-adornment .icon {
      color: $color-icon-lighter;
    }

    &__inner {
      font-size: $font-size-lg;
    }

    &__error__text {
      display: none;
    }
  }

  &__text {
    width: 320px;
  }

  &__select {
    min-width: 135px;
  }
}

.user-modal,
.contact-modal {
  min-height: 100%;
  background-color: $color-background-dark;

  .field {
    label {
      font-size: $font-size-lg;
      font-weight: bold;
    }
  }

  .auto-generate-password {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 0;
    font-size: $font-size-md;
  }
}

.my-company,
.supplier,
.branch {
  // Stock feed
  .stockfeed {
    &__item {
      display: flex;
      justify-content: space-between;

      & > span:first-of-type {
        color: $color-text-lighter;
        height: 32px;
        display: flex;
        align-items: center;
      }
    }
    form .stockfeed__item:last-of-type {
      margin-bottom: 0;
    }
    .field {
      margin: 0;
      width: auto;
      @include flex(column, flex-start, flex-start);

      // label {
      //   display: none;
      // }

      &__radio label {
        display: inline-block;
      }

      &__inner {
        width: 240px;
        margin: 0;
        font-size: $font-size-lg;

        &::placeholder {
          opacity: 1;
          color: $color-text-lighter;
        }
      }
    }
  }

  // Financial card
  .bank__card {
    &__item {
      display: flex;
      justify-content: space-between;

      & > span:first-of-type {
        color: $color-text-lighter;
        height: 32px;
        display: flex;
        align-items: center;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .field {
      margin: 0;
      width: auto;
      @include flex(column, flex-start, flex-start);

      label {
        display: none;
      }

      &__inner {
        width: 240px;
        margin: 0;
        font-size: $font-size-lg;

        &::placeholder {
          opacity: 1;
          color: $color-text-lighter;
        }
      }
    }
  }
}

.supplier,
.branch,
.customer,
.manage-customer-types,
.pricing-levels,
.pricing-levels-dialog,
.catalogue-preferences,
// .product-management,
.catalogue-management,
.catalogue-menegement-type-edit-dialog__content {
  // .card-list__container--branch-checkbox-list {
  //   max-height: 462px;
  //   overflow-y: auto;
  // }
  .card-list__container--checkbox-list {
    max-height: 462px;
    overflow-y: auto;
  }
  .card-list__container--def-price-level {
    .field {
      .field__container {
        margin-bottom: 0;
      }
      .field__error__text {
        display: none;
      }
    }
  }
  
  .card-list__container--checkbox-list-wrapper {
    padding: 12px;
    background: #f5f5f5;
    border: 1px solid #d9d9d9;
    margin-top: 0;
    border-radius: 3px;
    max-height: 358px;
    overflow: auto;
  }

  .checkbox-option {
    position: relative;
    background-color: $color-background;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 34.25px;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    .field__checkbox {
      .field__container {
        .control__checkbox {
          padding-left: 16px;
          margin: 8px 0;
        }
      }
    }
    &--def-stock {
      background-color: $color-background-disabled;
      .typography {
        color: $color-text-disabled;
      }
    }
  }
}

.currency {
  .list-card__dropdown-item {
    width: unset;
    white-space: nowrap;
  }
}

.system-settings {
  padding: 0;
  background-color: $color-background-dark;
  border: none;
  .list-card--settings {
    // @include flex(row, flex-start, flex-start);
    display: flex;
    align-items: center;
    
    height: 116px;
    padding: $space-lg;
    cursor: pointer;
    .list-card__title {
      &__primary {
        color: #4274a6;
        font-size: $font-size-xxl;
      }
      &__secondary {
        // white-space: normal;
        color: $color-text;
        font-weight: normal;
        font-size: $font-size-lg;
        line-height: 1.5;
      }
    }
    &:hover {
      border: 1px solid #4175a6;
    }
  }
}

.pricing-levels {
  .checkbox-option {
    .btn--text {
      visibility: hidden;
      margin-right: $space-md;
    }
    // button:last-of-type {
    button.delete-checkbox-option-btn {
      width: $space-md;
      height: $space-md;
      border: none;
      border-radius: 100%;
      background-color: transparent;
      cursor: pointer;
      .icon {
        margin: 0 auto;
      }
      &:hover {
        background-color: #eee;
        span.icon {
          color: $color-icon;
        }
      }
    }
    &:hover {
      .btn--text {
        visibility: visible;
      }
    }
  }
}

.customer-type-dialog,
.price-level-dialog,
.media-library-move-dialog,
.media-library-upload-dialog,
.media-library-send-dialog,
.catalogue-menegement-type-edit-dialog,
.catalogue-menegement-type-delete-dialog {
  .dialog__dialog {
    .dialog__content {
      background-color: $color-background-dark;
    }
    .dialog__actions {
      border-top: 1px solid #d9d9d9;
    }
  }
}

.role-settings-modal {
  form > .field label {
    font-size: $font-size-lg;
    font-weight: bold;
  }
  .tabs {
    .tabs__list {
      margin-bottom: $space-md;
    }
    .addEdit-role_content {
      border: 1px solid $color-border;
      border-radius: $border-radius-base;
      background-color: $color-background-dark;
      max-height: 418px;
      overflow-y: auto;
      .addEdit-role_permissions-section {
        position: relative;
        padding-left: 10px;
        padding-bottom: 10px;

        &::after {
          position: absolute;
          content: '';
          left: 0;
          top: 32px;
          width: 1px;
          height: calc(100% - 32px);
          background-color: lightgray;
        }

        &::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          height: 1px;
          width: 100%;
          background-color: lightgray;
        }

        .typography {
          line-height: 1;
          font-size: $font-size-lg;
          font-weight: bold;
        }

        &__options {
          background-color: $color-background;
          border: 1px solid $color-border;
          .field {
            @include flex(row, center, space-between);
            label {
              margin: 0;
              font-size: $font-size-lg;
              font-weight: normal;
            }
            .field__container {
              margin: 0;
              width: unset;
            }
            .field__error__text {
              display: none;
            }
          }
        }
      }
      .user--roles__card {
        background-color: $color-background;
        border: 1px solid $color-border;
        border-radius: $border-radius-base;
        cursor: default;
        .user__avatar {
          width: 40px;
          height: 40px;
        }
        .user__nameAndEmail {
          .user__name {
            font-weight: bold;
          }
          .user__email {
            font-size: $font-size-md;
            color: $color-text-lighter;
          }
        }
        .icon {
          margin-left: auto;
          min-width: 28px !important;
          min-height: 28px !important;
          border-radius: 100%;
          cursor: pointer;
          &:hover {
            color: $color-icon;
            background-color: $color-background-dark;
          }
        }
      }
    }
    // @media only screen and (min-height: 576px) {
    //   .addEdit-role_content {
    //     max-height: 418px;
    //   }
    // }
    @media only screen and (min-height: 768px) {
      .addEdit-role_content {
        max-height: 447px;
      }
    }
    @media only screen and (min-height: 900px) {
      .addEdit-role_content {
        max-height: 579px;
      }
    }
    @media only screen and (min-height: 1080px) {
      .addEdit-role_content {
        max-height: 759px;
      }
    }
  }
}

.roles-permissions {
  .card-list__container {
    .list-card--roles-permissions {
      &.role-1 {
        .list-card__avatar .icon {
          color: $color-primary;
        }

        .list-card__title {
          .list-card__title__primary {
            color: $color-primary;
          }
        }
      }

      &.role-0 {
      }

      .list-card__avatar {
        width: unset;
        height: unset;
      }
      .list-card__title {
        .list-card__title__primary {
          font-weight: bold;
        }
        .list-card__title__secondary {
          font-weight: normal;
          color: $color-text-lighter;
        }
      }
      .list-card__body {
        font-size: $font-size-md;
        .table-details__title {
          color: $color-text-lighter;
        }
        .table-details__value {
          font-weight: 500;
        }
      }
    }
  }
}
