.signup {
  height: 100%;
  padding: $space-xxl;
  @include flex(column, center, flex-start);
  width: 596px;
  background-color: $color-background;
  box-shadow: $box-shadow-light;
  transition: all 1s ease-in;

  &--visible {
    left: 0;
  }

  form {
    margin: auto;
    width: 100%;

    button {
      width: 100%;
      height: 48px;
      border-radius: $border-radius-base;
      border: none;
      cursor: pointer;
      color: $color-text-inverse;
      font-size: $font-size-xxl;
      font-weight: 700;
    }

    select {
      width: 100%;
    }

    .grid__row {
      margin: 0 -12px 16px -12px;
    }
  }
}
