.full-info {
    &__wrapper {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 24px;
        border-radius: 0 3px 3px 3px;
        position: relative;
        overflow-y: auto;
        height: 250px;
        max-height: 400px;
    }
}
