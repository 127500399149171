.input {
  &--reg {
    @include flex(row, center, flex-start);
    .input__input {
      input {
        background-color: $color-background-reg-input;
        border: 1px solid $color-background-reg-input;
        font-family: 'UKNumberPlate';
        font-size: $font-size-xxl;
        color: $color-primary;

        &:focus {
          background-color: $color-background-reg-input !important;
          border: 1px solid $color-background-reg-input !important;

          &::placeholder {
            color: transparent;
          }

          &:-ms-input-placeholder {
            color: transparent;
          }

          &::-ms-input-placeholder {
            color: transparent;
          }
        }

        &::placeholder {
          color: #000;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: #000;
        }

        &::-ms-input-placeholder {
          color: #000;
        }
      }
    }

    .input__flag {
      display: flex;
    }
  }

  &__flag {
    display: none;

    img {
      height: $space-xl;
    }
  }

  &__input {
    position: relative;
    width: 100%;

    input {
      width: 100%;
      height: $space-xl;
      border-radius: $border-radius-base;
      border: 1px solid $color-border;
      background-color: $color-background-dark;
      padding: 0 $space-sm;
      font-size: $font-size-md;
      color: $color-text;

      &.input__error {
        border: 1px solid $color-danger-light;
        margin-bottom: $space-xxs;
      }

      &:hover:not(.input__error) {
        border: 1px solid $color-border-dark;
      }

      &:focus:not(.input__error) {
        background-color: $color-background;
        border: 1px solid $color-primary;
      }

      &:disabled {
        background-color: $color-background-disabled;
        border: 1px solid $color-border-disabled;
        cursor: not-allowed;
      }

      &::-webkit-input-placeholder {
        color: $color-text-lighter;
      }
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // right: 12px;
    // cursor: pointer;
    &-search {
      left: 12px;
      cursor: text;
    }
  }
  &__error {
    color: $color-danger-light;
    font-size: $font-size-md;
  }
}

// input {
//   width: 100%;
//   height: $space-xl;
//   border-radius: $border-radius-base;
//   border: 1px solid $color-border;
//   padding: 0 $space-sm;
//   font-size: $font-size-md;
//   color: $color-text;

//   &.input__error {
//     border: 1px solid $color-danger-light;
//     margin-bottom: $space-xxs;
//   }

//   &:hover:not(.input__error) {
//     border: 1px solid $color-border-dark;
//   }

//   &:focus:not(.input__error) {
//     background-color: $color-background;
//     border: 1px solid $color-primary;
//   }

//   &:disabled {
//     background-color: $color-background-disabled !important;
//     border: 1px solid $color-border-disabled !important;
//     cursor: not-allowed;
//     color: $color-text-disabled !important;
//   }

//   &::-webkit-input-placeholder {
//     color: $color-text-lighter;
//   }
// }
