.my-company {
  .company-info-card,
  .charge__card {
    .field {
      margin: 0;
      width: auto;

      label {
        display: none;
      }

      &__inner {
        width: 240px;
        margin: 0;
        font-size: $font-size-lg;

        &::placeholder {
          opacity: 1;
          color: $color-text-lighter;
        }
      }
    }
  }

  // Company info
  .company-info {

    .company-info-card {
      &__item {
        display: flex;
        justify-content: space-between;

        & > span:first-of-type {
          color: $color-text-lighter;
          height: 32px;
          display: flex;
          align-items: center;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  // Charge card
  .charge__card {
    @include flex(column, normal, space-between);
    height: 100%;
    background-color: $color-background;
    border: 1px solid $color-border;
    border-radius: $border-radius-base;

    &__item {
      @include flex(row, center, space-between);
      height: 32px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .field {
      &__inner {
        width: 68px;
        text-align: right;
      }

      &__error__text {
        display: none;
      }
    }
    &--cost {
      .field {
        &__inner {
          width: 68px;
          padding: 0;
        }
      }
    }
  }
}
