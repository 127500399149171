.modal-body {
  width: 100%;
  padding: 24px;
}

.modal-body p {
  margin: 10px 0;
  color: #333;
}

.section-title {
  font-size: 18px;
  margin: 20px 0;
  color: #007bff;
}

.account-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px; /* Set a maximum height for the list */
  overflow-y: auto; /* Enable vertical scrolling if the content exceeds the max height */
  border: 1px solid #ddd; /* Add a border for the scrollable area */
  padding: 10px; /* Add padding to make it look better */
}

.account-info {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.account-type {
  text-transform: capitalize;
}

.account-name {
  color: #555;
}

.user-info p {
  margin: 10px 0;
  color: #333;
}

.recipients-title {
  text-decoration: underline;
  color: #333;
  font-weight: bold;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #333;
  margin: 24px 0px;
}

.recipients-container {
  margin-top: 24px;
}

.date-container {
  margin-top: 24px;
}
