.empty-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 56px $space-md;

  &__title {
    font-size: 24px;
    font-weight: 500;
  }

  &__description {
    max-width: 334px;
    text-align: center;
    font-size: $font-size-lg;
    margin-top: 8px;
  }
}