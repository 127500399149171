body,
html {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: $color-background-dark;
  font-size: $font-size-lg;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;

  &.overflow-hidden {
    overflow: hidden !important;
  }
}

.main {
  margin-top: 64px;
  overflow: auto;
  height: calc(100vh - 64px);

  &--auth {
    margin-top: 0;
    height: 100vh;
  }

  &--client {
    margin-left: 0;
  }

  &--admin {
    margin-left: 240px;
  }
}

.branch-details,
.acc-details {
  .selector {
    width: 100%;
  }

  .input:not(:last-child),
  .selector {
    margin-bottom: $space-md;
  }
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize($color: $color-secondary, $amount: 0.25);
  z-index: 1050;
}

.overlay-loading {
  @extend .overlay;
  z-index: 1200;
}

.additional-info {
  min-height: 100px;
  overflow-y: auto;
  width: 350px;
  border: 1px solid $color-border;
  padding: $space-md;
  border-radius: $border-radius-base;
  color: $color-text;
  word-wrap: break-word;
  text-align: left;
  resize: none;
}

.auto-generate {
  cursor: pointer;
  color: $color-text-active;
}

.auth-container {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.copy-partnumber {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.empty-page {
  border: 2px dashed $color-border-dark;
  max-width: 400px;
  margin: 150px auto 0 auto;
  padding: $space-xxl;
  text-align: center;

  h1 {
    font-weight: 400;
    margin-bottom: $space-xxl;
    text-transform: capitalize;
  }
}

.invalid-error {
  display: flex;
  align-items: center;
  border: 1px solid $color-danger;
  border-radius: $border-radius-base;
  background-color: #fff1f0;
  padding: $space-sm;

  i {
    font-size: $font-size-xxxl;
    margin-right: $space-xs;
    color: $color-danger;
  }
}

.vcount {
  @include flex(row, center, flex-start);
  position: absolute;
  right: $space-md;
  top: $space-md;

  &__text {
    color: $color-danger;
    font-size: $font-size-md;
    margin-right: $space-xxs;

    &--single {
      color: $color-success;
    }
  }
}

.search {
  width: 100%;
  .input {
    width: 100%;
  }
}

.no-image {
  img {
    max-height: 200px;
    max-width: 100%;
  }
}

.custom-row-underline {
  cursor: pointer;
}

.viewer-open {
  padding: 0 !important;
}

.imgs {
  max-width: 400px;
  .image {
    width: auto;
    max-height: 265px;
    margin: 0 auto;
    max-width: 100%;
  }

  .imagePreview {
    object-fit: contain;
    width: 100%;
  }
}

.no-image-found {
  @include flex(row, center, center);
  height: 89px;
  text-align: center;
}

iframe {
  pointer-events: none;
}
