.slider {
   width: 100%;

   &__primary {
       margin-bottom: $space-sm;
       img {
           border-radius: $border-radius-base;
           width: 100%;
           height: 714px;
           object-fit: cover
       }
   }

   &__secondary {
       @include flex(row, flex-start, flex-start);
   }

   &__item {
       flex-basis: calc(33.333% - #{$space-xs});
       margin-right: $space-sm;
       background-size: cover;
       background-repeat: no-repeat;
       background-position: 50%;
       padding-bottom: 18.75%;
       border-radius: $border-radius-base;

       &:last-child {
           margin-right: 0;
       }
   }
}


// slick slider class for product image preview
.dots-custom-class {
    height: fit-content;
    display: flex !important;
    justify-content: center;
    position: absolute;
    top: 100%;
    padding-top: 20px;
    
    li {
        width: fit-content;
        height: fit-content;
        border: 1px solid grey;
        border-radius: 4px;
        
        .dots-custom-class-image {
            width: 100px;
        }
    }
}