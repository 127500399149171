#landed-prices-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    gap: 16px;
}

.pricing-add-dropzone {
    width: 100%;
    padding: 0px !important;
}

.trash-red {
    span {
        color: #ee5454;
    }
}

.history-records-data {
    margin-top: 24px;

    border-bottom: 1px solid #d9d9d9;

    padding-bottom: 14px;
}

.history-records-title {
    font-weight: bold;
    padding-left: 24px;
}

.landed-price-title {
    color: #2569a1;
    font-weight: bold;
}

.history-records-list {
    background-color: #f5f5f5;
    height: 100vh;

    padding: 24px;
}

.prices-history-dialog-content {
    background-color: white;
}

.price-edit-modal-container {
    overflow-x: scroll;
    background-color: #f5f5f5;
    min-height: 500px;
    border: 1px solid #d9d9d9;
    padding: 1rem;
    margin: 1rem;
}

.add-prefix-suffix-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 16px;
}

.total-wrapper {
    display: flex;
    border: 1px solid rgb(222, 222, 222);
}

.total-container {
    border-right: 1px solid rgb(222, 222, 222);
    width: 33.3%;
    padding: 12px;
}

.add-cost-trash-button {
    cursor: pointer;
    padding: 6px;
    &:hover {
        background-color: #f5f5f5;
        border-radius: 50%;
        span {
            color: #ee5454;
        }
    }
}

.pricing-table-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}