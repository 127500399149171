.test-polygon-table {
  tbody {
    tr {
      td {
        padding: 15px;
      }
      &:nth-of-type(3) {
        .dropdown {
          width: 100%;
          .user-dropdown-button {
            border: none;
            border-radius: 100%;
            margin-left: 100%;
            transform: translateX(-100%);
            background-color: transparent;
            cursor: pointer;
            min-width: 28px;
            min-height: 28px;
      
            &:hover {
              background-color: #eee;
      
              span {
                color: $color-icon;
              }
            }
          }
          .user-dropdown-item {
            width: 150px;
            display: flex;
            align-items: center;
      
            .icon {
              margin-right: $space-xs;
            }
      
            &.red {
              color: $color-danger;
      
              .icon {
                color: $color-danger;
              }
            }
          }
        }
      }
    }
  }
  &--icons {
    tbody {
      tr {
        td {
          padding: 15px 0;
          width: 100px;
          text-align: center;
          span {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.test-polygon-spacing.padding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .spacing-block {
    position: relative;
    width: calc((100% / 7) - 15px);
    padding-bottom: calc((100% / 7) - 15px);
    margin-top: 20px;

    & > span {
      position: absolute;
      top: -20px;
      left: 0;
      font-size: 12px;
    }

    & > div {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #ABE4A8;

      & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background-color: white;
      }
    }
  }
}

.test-polygon-spacing.margin {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: #ABE4A8;

  .spacing-block {
    width: 100%;

    & > div {
      background-color: white;
      padding: 8px;
    }
  }
}

.test-polygon-colors {
  display: flex;
  flex-wrap: wrap;
  row-gap: 39px;
  column-gap: 100px;
  .color-card {
    display: flex;
    min-width: 285px;
    .color-box {
      width: 115px;
      height: 115px;
      display: flex;
      flex-wrap: wrap;
      .default {
        width: 100%;
        height: 50%;
      }
      .dark-light {
        width: 50%;
        height: 50%;
      }
    }
    .color-description {
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      h4 {
        font-size: 20px;
        margin-bottom: 16px;
      }
      span {
        color: #999999;
        margin-bottom: 8px;
        .values {
          margin-left: 8px;
          color: #333333;
          display: inline;
        }
      }
    }
  }
  &--gray{
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 33px;
    column-gap: 24px;
    .color-card--grey {
      display: flex;
      flex-direction: column;
      min-width: 254px;
      height: 254px;
      border: 1px solid #D9D9D9;
      .color-box {
        height: 50%;
      }
      .color-description {
        padding: 16px;
        height: 50%;
        display: flex;
        flex-direction: column;
        background-color: white;
        h4 {
          font-size: 14px;
          margin-bottom: 12px;
        }
        span {
          color: #999999;
          margin-bottom: 8px;
          .values {
            margin-left: 8px;
            color: #333333;
            display: inline;
          }
        }
      }
    }
  }
}