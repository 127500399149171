.stock-providers {

    &__default {
        margin-bottom: $space-lg;

        > div {

            &:first-child {
                font-weight: 500;
            }

            &:last-child {
                @include flex(row, center, space-between);
                padding: $space-sm;
                border-radius: $border-radius-base;
                background-color: $color-background-darker;
                margin-top: $space-sm;
            }
        }
    }
}