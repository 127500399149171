.btn {
  cursor: pointer;
  display: inline-block;
  outline: none;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  user-select: none;

  &:disabled {
    cursor: not-allowed !important;
  }

  // Variant styles
  &--primary {
    border: none;

    &--success {
      border: none;
      background-color: #22c55e !important;
      color: $color-text-inverse !important;

      &:not(:disabled) {
        &:hover {
          background-color: #16a34a !important;
        }
        &:active {
          background-color: #15803d !important;
        }
      }

      &:disabled {
        background-color: #86efac !important;
      }
    }

    &--danger {
      border: none;
      background-color: #ef4444;
      color: $color-text-inverse;

      &:not(:disabled) {
        &:hover {
          background-color: #dc2626;
        }

        &:active {
          background-color: #dc2626;
          color: #fecaca;
        }
      }

      &:disabled {
        background-color: #fca5a5;
      }
    }

    // regular colors
    &:not(.btn--destructive) {
      background-color: #4298d0;
      color: $color-text-inverse;

      &:not(:disabled) {
        &:hover {
          background-color: #388bc4;
        }

        &:active {
          background-color: #388bc4;
          color: #97cae6;
        }
      }

      &:disabled {
        background-color: #73b6dc;
      }
    }

    // destructive colors
    &.btn--destructive {
      background-color: #ef4444;
      color: $color-text-inverse;

      &:not(:disabled) {
        &:hover {
          background-color: #dc2626;
        }

        &:active {
          background-color: #dc2626;
          color: #fecaca;
        }
      }

      &:disabled {
        background-color: #fca5a5;
      }
    }
  }

  &--secondary {
    border: none;

    &--success {
      border: none;
      background-color: #22c55e !important;
      color: $color-text-inverse !important;

      &:not(:disabled) {
        &:hover {
          background-color: #16a34a !important;
        }
        &:active {
          background-color: #15803d !important;
        }
      }

      &:disabled {
        background-color: #86efac !important;
      }
    }

    &--danger {
      border: none;
      background-color: #ef4444;
      color: $color-text-inverse;

      &:not(:disabled) {
        &:hover {
          background-color: #dc2626;
        }

        &:active {
          background-color: #dc2626;
          color: #fecaca;
        }
      }

      &:disabled {
        background-color: #fca5a5;
      }
    }

    // regular colors
    &:not(.btn--destructive) {
      background-color: #e4f2f8;
      color: #4298d0;

      &:not(:disabled) {
        &:hover {
          background-color: #bddef0;
          color: #388bc4;
        }

        &:active {
          background-color: #bddef0;
        }
      }

      &:disabled {
        background-color: #e4f2f8;
        color: #73b6dc;
      }
    }

    // destructive colors
    &.btn--destructive {
      background-color: #fef2f2;
      color: #ef4444;

      &:not(:disabled) {
        &:hover {
          background-color: #fee2e2;
          color: #dc2626;
        }

        &:active {
          background-color: #fee2e2;
        }
      }

      &:disabled {
        background-color: #fef2f2;
        color: #fca5a5;
      }
    }
  }

  &--tertiary {
    background-color: $color-background;

    &--success {
      border: none;
      background-color: #22c55e !important;
      color: $color-text-inverse !important;

      &:not(:disabled) {
        &:hover {
          background-color: #16a34a !important;
        }
        &:active {
          background-color: #15803d !important;
        }
      }

      &:disabled {
        background-color: #86efac !important;
      }
    }

    &--danger {
      border: none;
      background-color: #ef4444;
      color: $color-text-inverse;

      &:not(:disabled) {
        &:hover {
          background-color: #dc2626;
        }

        &:active {
          background-color: #dc2626;
          color: #fecaca;
        }
      }

      &:disabled {
        background-color: #fca5a5;
      }
    }

    // regular colors
    &:not(.btn--destructive) {
      border: 1px solid #e5e7eb;
      color: #374151;

      &:not(:disabled) {
        &:hover {
          background-color: #f9fafb;
          color: #374151;
          border: 1px solid #d1d5db;
        }

        &:active {
          background-color: #f9fafb;
          color: #6b7280;
          border: 1px solid #d1d5db;
        }
      }

      &:disabled {
        color: #9ca3af;
      }
    }

    // destructive colors
    &.btn--destructive {
      border: 1px solid #fecaca;
      color: #ef4444;

      &:not(:disabled) {
        &:hover {
          background-color: #fef2f2;
          color: #ef4444;
          border: 1px solid #fca5a5;
        }

        &:active {
          background-color: #fef2f2;
          color: #f87171;
          border: 1px solid #fca5a5;
        }
      }

      &:disabled {
        color: #fca5a5;
      }
    }
  }

  &--outlined {
    background-color: transparent;

    &--success {
      border: 1px solid #22c55e;
      color: #22c55e;
      background-color: transparent;

      &:not(:disabled) {
        &:hover {
          border: 1px solid #16a34a;
          color: #16a34a;
        }
        &:active {
          border: 1px solid #15803d;
          color: #15803d;
        }
      }

      &:disabled {
        border: 1px solid #86efac;
        color: #86efac;
      }
    }

    &--danger {
      border: 1px solid #ef4444;
      color: #ef4444;
      background-color: transparent;

      &:not(:disabled) {
        &:hover {
          border: 1px solid #dc2626;
          color: #dc2626;
        }
        &:active {
          border: 1px solid #dc2626;
          color: #dc2626;
        }
      }

      &:disabled {
        color: #fca5a5;
        border: 1px solid #fca5a5;
      }
    }

    // regular colors
    &:not(.btn--destructive) {
      border: 1px solid #4298d0;
      color: #4298d0;

      &:not(:disabled) {
        &:active {
          color: #59a6d6;
          border: 1px solid #59a6d6;
        }
      }

      &:disabled {
        color: #73b6dc;
        border: 1px solid #73b6dc;
      }
    }

    // destructive colors
    &.btn--destructive {
      border: 1px solid #ef4444;
      color: #ef4444;

      &:not(:disabled) {
        &:active {
          color: #f87171;
          border: 1px solid #f87171;
        }
      }

      &:disabled {
        color: #fca5a5;
        border: 1px solid #fca5a5;
      }
    }
  }

  &--link {
    position: relative;
    border: none;
    background-color: transparent;

    // regular colors
    &:not(.btn--destructive) {
      color: #4298d0;

      &:not(:disabled) {
        &:hover {
          color: #388bc4;
        }

        &:active {
          color: #59a6d6;
        }
      }

      &:disabled {
        color: #73b6dc;
      }
    }

    // destructive colors
    &.btn--destructive {
      color: #ef4444;

      &:not(:disabled) {
        &:hover {
          color: #dc2626;
        }

        &:active {
          color: #ef4444;
        }
      }

      &:disabled {
        color: #fca5a5;
      }
    }
  }

  // Size styles
  &--large {
    &:not(.btn__icon) {
      padding: $space-md $space-xl;
      border-radius: 13px;
      font-size: 16px;
      line-height: 24px;
    }
    &.btn__icon {
      width: 45px;
      height: 45px;
    }
  }
  &--medium {
    &:not(.btn__icon) {
      padding: $space-sm $space-lg;
      border-radius: 11px;
      font-size: 14px;
      line-height: 20px;
    }
    &.btn__icon {
      width: 35px;
      height: 35px;
    }
  }
  &--small {
    &:not(.btn__icon) {
      padding: $space-xs $space-sm;
      border-radius: 7px;
      font-size: 12px;
      line-height: 16px;
    }
    &.btn__icon {
      width: 25px;
      height: 25px;
    }
  }

  // Styles when button have only icon in children (isIcon prop)
  &__icon {
    border: none !important;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:not(.btn__icon) {
    & > .icon {
      display: inline-block;
    }

    .icon:first-child {
      margin-right: $space-xs;
    }

    .icon:last-child {
      margin-left: $space-xs;
    }
  }
}
