@mixin selectList() {
  .field__inner__menu {
    margin-top: $space-xs;
    box-shadow: none !important;
    border: 1px solid $color-primary;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  .field__inner__option {
    cursor: pointer !important;
    display: flex;
    align-items: center;

    &--is-focused {
      background-color: $color-background-dark !important;
    }

    &--is-selected {
      background-color: $color-primary !important;
    }

    .option-icon {
      border-radius: 50%;
      height: 20px;
      width: 20px;
      margin-right: 10px;
      object-fit: cover;
    }
  }
}

.field {
  @include flex(column, flex-start, flex-start);
  margin-bottom: $space-xs;

  &--disabled {
    background-color: $color-background-disabled;
    border: 1px solid $color-border-disabled;
    background-image: none;
    color: $color-text-disabled;

    &:hover {
      border-color: $color-border-disabled !important;
    }
  }

  label {
    display: block;
    font-weight: 500;
    margin-bottom: $space-xxs;
    font-size: $font-size-md;
    text-transform: capitalize;
  }

  &__container {
    position: relative;
    width: 100%;
    margin-bottom: $space-xxs;
  }

  &__helper {
    font-size: $font-size-md;
    color: $color-text-light;
    margin-bottom: $space-xxs;
  }

  &__error__text {
    display: inline-block;
    color: $color-danger-light;
    font-size: $font-size-md;
    max-height: 14px;

    &--disable {
      display: none;
    }
  }

  &__start-adornment,
  &__end-adornment {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    //pointer-events: none;
  }

  &__start-adornment {
    left: $space-sm;
  }

  &__end-adornment {
    right: $space-sm;

    .icon {
      &.error-icon {
        color: $color-danger;
      }

      &.success-icon {
        color: $color-success;
      }
    }
  }

  // Styles by types

  &__hidden {
    display: none;
  }

  &__text,
  &__password,
  &__date,
  &__textarea {
    cursor: text;

    .field__inner {
      border: 1px solid #e5e7eb;
      height: 44px;
      padding: $space-sm;
      font-size: $font-size-lg;
      border-radius: 11px;
      color: #111827;
      width: 100%;

      &:hover {
        border-color: #73b6dc;
      }

      &:focus {
        border-color: #73b6dc;
        box-shadow: 0px 0px 0px 4px rgba(66, 152, 208, 0.12);
      }

      &--line {
        border: 0;
        border-bottom: 1px solid #e5e7eb;
        border-radius: 0;
        box-shadow: none !important;
      }
    }
  }

  &__text {
    .field__inner {
      padding-right: $space-lg;
    }
  }

  &__select {
    .field__inner {
      width: 100%;
      font-size: $font-size-md;

      &.field__is-searchable {
        .field__inner__indicator-separator {
          display: block !important;
        }
      }

      &__control {
        cursor: pointer !important;
        border: 1px solid #e5e7eb;
        height: 44px;
        min-height: 44px !important;
        padding: 0 $space-sm;
        font-size: inherit;
        border-radius: 11px !important;
        //color: $color-text-light;
        width: 100%;
        //margin-bottom: $space-xxs;
        box-shadow: none !important;

        &--is-focused {
          border-color: #73b6dc !important;
        }

        &--menu-is-open {
          box-shadow: 0px 0px 0px 4px rgba(66, 152, 208, 0.12) !important;
          .field__inner__indicator svg {
            transform: rotate(-180deg);
          }
        }
      }

      &__single-value {
        color: $color-text;
      }

      &__placeholder {
        margin-left: 0;
      }

      &__value-container {
        padding-left: 0 !important;
      }

      &__indicator-separator {
        display: none !important;
      }

      &__indicator {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-right: 0 !important;

        & > svg {
          width: 16px;
          height: 16px;
          color: $color-icon;
          transition: all 0.25s ease-in-out;
        }
      }

      @include selectList();

      //&__menu {
      //  margin-top: $space-xs;
      //  box-shadow: none !important;
      //  border: 1px solid $color-primary;
      //
      //  ::-webkit-scrollbar {
      //    -webkit-appearance: none;
      //    width: 7px;
      //    background: transparent;
      //  }
      //
      //  ::-webkit-scrollbar-thumb {
      //    border-radius: 4px;
      //    background-color: rgba(0, 0, 0, .5);
      //    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      //  }
      //
      //  ::-webkit-scrollbar-track {
      //    background: transparent;
      //  }
      //}

      &__menu-list {
      }

      //&__option {
      //  cursor: pointer !important;
      //
      //  &--is-focused {
      //    background-color: $color-background-dark !important;
      //  }
      //
      //  &--is-selected {
      //    background-color: $color-primary !important;
      //  }
      //}
    }
    &--multi {
      .field__inner {
        &__control {
          height: auto;
        }
      }
    }
  }

  &__switch {
    label {
      margin-bottom: $space-xs;
    }

    .field__inner {
      display: none;
    }

    .switch__toggle {
      position: relative;
      cursor: pointer;
      width: 56px;
      height: 24px;
      display: block;
      border-radius: 3px;
      border: 1px solid $color-border;
      background-color: $color-danger;

      &__yes,
      &__no {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: white;
        font-size: 12px;
        user-select: none;
      }

      &__yes {
        display: none;
        left: $space-xs;
      }

      &__no {
        right: $space-xs;
      }

      &::after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 18px;
        height: 18px;
        background: #ffffff;
        border-radius: 2px;
        transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      }

      &--disabled {
        background: $color-background-disabled !important;
        border: 1px solid $color-text-disabled;

        &::after {
          background: $color-text-disabled;
        }
        .switch__toggle__yes,
        .switch__toggle__no {
          color: $color-text-disabled;
        }
      }
    }

    .field__inner:checked {
      & + .switch__toggle {
        background-color: $color-success;

        .switch__toggle__yes {
          display: block;
        }

        .switch__toggle__no {
          display: none;
        }
      }
      .switch__toggle {
      }
    }

    .field__inner:checked {
      & + .switch__toggle::after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }
    }
  }

  &__checkbox,
  &__radio {
    margin-bottom: 0;

    &.field--full-width {
      width: auto;
    }

    .field__container {
      margin-bottom: 0;
    }

    .field__error__text {
      display: none;
    }

    .field__inner {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    .control {
      position: relative;
      padding-left: $space-lg;
      margin: $space-xs $space-lg $space-xs 0;
      display: inline-flex;
      align-items: center;

      span {
        //display: inline-block;
        cursor: pointer;
        user-select: none;
        font-size: $font-size-lg;
        font-weight: 400;
        height: 16px;
      }
    }

    .indicator {
      position: absolute;
      top: 50%;
      left: 0;
      width: 16px;
      height: 16px;
      transform: translateY(-50%);
      border: 1px solid $color-primary;
      cursor: pointer;
    }

    /* Hover and focus states */
    .control:hover input ~ .indicator,
    .control input:focus ~ .indicator {
      background: transparentize($color-border-dark, 0.8);
    }

    /* Checked state */
    .control input:checked ~ .indicator {
      background: transparentize($color-primary, 0.1);
    }

    /* Hover state whilst checked */
    .control:hover input:not([disabled]):checked ~ .indicator,
    .control input:checked:focus ~ .indicator {
      background-color: $color-primary;
    }

    /* Check mark */
    .indicator::after {
      position: absolute;
      display: none;
      content: '';
    }

    /* Show check mark */
    .control input:checked ~ .indicator::after {
      display: block;
    }

    /* Checkbox tick */
    .control--checkbox .indicator::after {
      top: 4px;
      left: 8px;
      width: 3px;
      height: 8px;
      transform: rotate(45deg);
      border: solid #fff;
      border-width: 0 2px 2px 0;
    }

    /* Disabled tick colour */
    .control--checkbox input:disabled ~ .indicator::after {
      border-color: $color-border-disabled;
    }

    .control .indicator::after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      background: #fff;
    }

    /* Disabled state */
    .control input:disabled {
      & ~ .indicator,
      & ~ span {
        cursor: not-allowed;
        pointer-events: none;
      }

      & ~ .indicator {
        opacity: 0.6;
        background-color: $color-border-dark;
        border-color: $color-border-disabled;

        &::after {
          background: $color-border-disabled;
          border-color: $color-border-disabled;
        }
      }

      & ~ span {
        color: $color-border-disabled;
      }
    }
  }

  &__checkbox {
    .control .indicator {
      border-radius: $border-radius-base;

      &::after {
        border-width: 2px 2px 0 0;
        background-color: transparent;
        border-style: solid;
        border-color: white;
        width: 7px;
        height: 4px;
        transform: translate(-50%, -75%) rotate(135deg);
      }
    }
  }

  &__radio {
    .indicator {
      border-radius: 100%;
    }

    .control .indicator::after {
      border-radius: 100%;
    }
  }

  &__date {
  }

  &__textarea {
    .field__inner {
      // min-height: 32px;
      min-height: 100px;
      max-width: 100%;
      min-width: 100%;
    }
  }

  // States

  &--required {
    label {
      &::after {
        content: '*';
        color: #eb3b3b;
        margin-left: 3px;
      }
    }
  }

  &--full-width {
    width: 100%;

    &__container {
      position: relative;
    }
  }

  &--with-start-adornment {
    position: relative;

    .field__inner {
      padding-left: $space-xxl;
    }
  }

  &--with-end-adornment {
    position: relative;

    .field__inner {
      padding-right: $space-xxl;
    }
  }

  &--is-valid {
    .field__inner {
      border-color: $color-success !important;

      .field__inner__control {
        border-color: $color-success !important;
      }
    }
  }

  &--is-invalid {
    .field__inner {
      border-color: #fca5a5 !important;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;

      .field__inner__control {
        border-color: #fca5a5 !important;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
      }

      &:focus {
        border-color: #f87171 !important;
        box-shadow: 0px 0px 0px 4px rgba(239, 68, 68, 0.12) !important;
      }

      &--line {
        border: 0;
        border-bottom: 1px solid #fca5a5;

        &:focus {
          border-color: #f87171 !important;
          box-shadow: none !important ;
        }
      }
    }
  }
}

.field-view-only {
  cursor: default;

  .field__inner {
    border: 0;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    text-align: right;
    background-color: transparent;

    &__control {
      background-color: transparent;
      border: 0;
    }

    &__indicators {
      display: none;
    }

    .field__inner {
      padding-right: 0 !important;

      .field__inner__placeholder {
        margin-right: 0;
      }
    }
  }

  .field__end-adornment {
    .icon {
      visibility: hidden;
    }
  }

  .field__error__text {
    visibility: hidden;
  }

  input::placeholder {
    color: #333333 !important;
    font-weight: 500 !important;
  }
}

//Select portal
.field__inner__menu-portal {
  z-index: 1300 !important;

  .field__inner__menu {
    .field__inner__menu-list {
      overflow-x: hidden !important;
    }
  }

  @include selectList();
}
