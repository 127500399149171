.btn-switcher {
  padding: $space-xxxs;
  border-radius: $border-radius-base;
  border: 1px solid $color-border;
  background-color: $color-background-dark;
  display: flex;
  flex-direction: column;
  row-gap: $space-xxxs;
  .btn {
    padding: $space-xxxs $space-sm;
  }
}
