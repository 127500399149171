.checkbox {
    position: relative;
    display: block;
    width: $checkbox-size;
    height: $checkbox-size;

    &__btn {
        position: absolute;
        left: -9999px;
      
        & + label {
            position: relative;
            display: inline-block;
            cursor: pointer;
            border: 1px solid $color-primary;
            width: 100%;
            height: 100%;
            background-color: $color-background-dark;
            border-radius: $border-radius-base;

        
            &:before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%) rotate(-45deg);
                border-color: $color-background;
                border-style: none none solid solid;
                width: $space-xs;
                height: $space-xxs;
                border-width: 2px;
                display: none;
            }
        }
        
        &:checked + label {
            background-color: $color-primary;
            
            &:before {
                display: block;
            }
        }
    }
}