.header {
  height: 64px;
  @include flex(row, center, space-between);
  padding: 0 24px;
  border-bottom: 1px solid #dedede;
  background-color: $color-background;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 105;

  &__right {
    @include flex(row, center, flex-start);
  }

  &__icons {
    // @include flex(row, space-between, center);
    display: flex;
    margin-right: 48px;

    .icon-box {
      width: 40px;
      height: 40px;
      @include flex(row, center, center);
      border-radius: 50%;
      position: relative;
      .badge {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .cart-icon-box {
      .icon {
        color: transparentize($color: $color-icon, $amount: 0.5);
      }

      .badge {
        position: absolute;
        // left: 5px;//calc(100% - 20px);
        right: -10px;
        top: 0;
      }

      &:hover {
        background-color: transparentize($color: $color-icon, $amount: 0.9);

        .icon {
          color: transparentize($color: $color-icon, $amount: 0);
        }
      }
    }

    .chat-icon-box {
      margin-right: 8px;

      .icon {
        color: transparentize($color: $color-icon, $amount: 0.5);
      }

      &:hover {
        background-color: transparentize($color: $color-icon, $amount: 0.9);

        .icon {
          color: transparentize($color: $color-icon, $amount: 0);
        }
      }
    }

    .bell-neutral-icon-box {
      .icon {
        color: transparentize($color: $color-icon, $amount: 0.5);
      }

      &:hover {
        background-color: transparentize($color: $color-icon, $amount: 0.9);

        .icon {
          color: transparentize($color: $color-icon, $amount: 0);
        }
      }
    }
  }

  .tabs.v-2 {
    height: 100%;

    .tabs__list {
      height: 100%;
      align-items: stretch;
      margin-bottom: 0;
      border: none;

      .tab {
        padding: 0 $space-sm;

        .tab__link {
          display: flex;
          align-items: center;
          height: 100%;
          font-size: 16px;
        }
      }
    }
  }
}
