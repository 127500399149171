.media-library {
  .list-card--media-library {
    padding: 0;
    cursor: pointer;

    &:hover {
      border: 1px solid $color-border-active;
    }

    &__content {
      position: relative;
      height: 183px;
      display: flex;
      align-items: center;
      justify-content: center;

      .list-card--media-library__image {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      .list-card--media-library__thumbnails {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        list-style: none;
        padding: 0;
        margin: 0;

        &.count--1 {
          li {
            width: 100%;
            height: 100%;
            border: none !important;
          }
        }

        &.count--2 {
          li {
            width: 50%;
            height: 100%;
          }
        }

        &.count--3,
        &.count--4 {
          li {
            width: 50%;
            height: 50%;
          }
        }

        li {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          &:nth-of-type(1) {
            border-right: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
          }

          &:nth-of-type(2) {
            border-left: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
          }

          &:nth-of-type(3) {
            border-right: 1px solid #d9d9d9;
            border-top: 1px solid #d9d9d9;
          }

          &:nth-of-type(4) {
            border-left: 1px solid #d9d9d9;
            border-top: 1px solid #d9d9d9;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .loader-linear {
        bottom: 0;
        height: 2px;
      }
    }

    &__description {
      position: relative;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 $space-md;
      border-top: 1px solid #d9d9d9;

      & > .field {
        overflow: hidden;

        .control {
          width: 100%;

          & > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      & > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .list-card__dropdown {
        //top: auto;
        //right: $space-md;
        position: relative;
        margin-left: 10px;
        .dropdown__list {
          max-height: unset !important;
        }
      }
    }
  }

  .empty-content {
    color: $color-text-lighter;

    &__description {
      font-size: 16px;
    }
  }

  &__header-checked-right-section {
    .btn {
      color: $color-text;

      &:hover {
        .icon {
          color: #388bc4;
        }
      }

      &:last-of-type:hover {
        .icon {
          color: #dc2626;
        }
      }
    }
  }
}

.media-library-send-dialog {
  .dialog__dialog {
    width: 1024px;
  }

  &__content {
    min-height: 100%;
  }

  &__side-panel {
    width: 252px;
  }
  &__main-section {
    max-width: 724px;
  }

  &__side-panel,
  &__files-section,
  &__recipients-section,
  &__title-section {
    background-color: $color-background;
    border-radius: 3px;
  }

  &__items {
    width: 100%;
    flex-wrap: wrap;
    gap: $space-md;
  }
  &__item {
    background-color: $color-secondary;
    color: $color-text-inverse;
    font-size: $font-size-md;
    min-height: 24px;
    max-width: 166px;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .icon:hover {
      background-color: $color-danger;
      &::before {
        color: $color-icon-inverse;
      }
    }
  }
}

.media-library-move-dialog__content {
  .media-library-move__folders {
    background-color: $color-background;
    border: 1px solid $color-border;
    max-height: 140px;
    overflow-y: auto;

    .media-library-move__folder {
      background-color: transparent;
      border: 0;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: transparentize($color-text-active, 0.9);
      }

      &.selected {
        background-color: transparentize($color-text-active, 0.7);
      }

      span:first-of-type {
        flex-shrink: 0;
      }

      span:last-of-type {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
