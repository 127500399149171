.table-details {
  position: relative;
  width: 100%;

  table {
    tr {
      font-size: $font-size-md;

      td {
        padding-top: $font-size-xs;

        &.table-details__title {
          color: $color-text-lighter;

          span {
            white-space: nowrap;
          }
        }

        &.table-details__value {
          position: relative;
          width: 100%;
          font-weight: 500;
          color: $color-text;
          padding-left: $space-xs;

          span {
            position: absolute;
            top: $font-size-xs;
            left: $font-size-xs;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}