.change-quantity {
    @include flex(row, center, center);
    height: $space-xl;

    &__button {
        height: 100%;
        width: $space-xl;
        background-color: $color-background-inverse;
        color: $color-text-inverse;
        border: none;
        cursor: pointer;
        border: 1px solid $color-secondary;
    }

    &__quantity {
        height: 100%;
        width: $space-xl;
        @include flex(row, center, center);
        border: 1px solid $color-secondary;
    }
}