.grid {
    width: 100%;

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -#{$space-xs} $space-md -#{$space-xs};

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__column {

        @for $i from 1 through $grid-columns {
            &--#{$i} {
              width: percentage($i / $grid-columns);
              padding: 0 $space-xs;
            }
        }
    }
}