
.image__select {
  width: 100%;
  //margin-bottom: $space-lg;
  @include flex(row, center, flex-start);

  &__display {
    width: 100%;
    height: 80px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    //background-color: lightgrey;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNS40LjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNzYgMjc2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNzYgMjc2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojMjU2OUExO30NCjwvc3R5bGU+DQo8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwyNzYuMDAwMDAwKSBzY2FsZSgwLjEwMDAwMCwtMC4xMDAwMDApIj4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMCwxNDUzVjE0NWwyNCw1NmM1OCwxNDIsMTM0LDE5Nyw0MzEsMzE0YzI2NCwxMDQsMzc3LDE2OSw1MDgsMjk0bDY3LDY0djkzYzAsODgtMiw5NS0zNSwxNTINCgkJYy00Miw3MC04NSwxNzgtMTE2LDI5MGMtMTYsNTktMjcsODItNDAsODVjLTI4LDgtNTIsNzMtNTEsMTQyYzAsMzMsMSwxMjgsMSwyMTBjMCwyNzYsNTAsNDcwLDE0MSw1NDhjNDksNDIsMTQ3LDkwLDIzMSwxMTQNCgkJYzkxLDI1LDM0NywyNSw0MzgsMGM4NC0yNCwxODItNzIsMjMxLTExNGM5MS03OCwxNDEtMjcyLDE0MS01NDhjMC04MiwxLTE3NywxLTIxMGMxLTY5LTIzLTEzNC01MS0xNDJjLTEzLTMtMjQtMjYtNDAtODUNCgkJYy0zMS0xMTItNzQtMjIwLTExNi0yOTBjLTMzLTU3LTM1LTY0LTM1LTE1MnYtOTNsNjgtNjRjMTMwLTEyNSwyNDMtMTkwLDUwNy0yOTRjMjk3LTExNywzNzMtMTcyLDQzMS0zMTRsMjQtNTZ2MTMwOHYxMzA3SDEzODBIMA0KCQlWMTQ1M3oiLz4NCjwvZz4NCjwvc3ZnPg0K');

    &.round {
      width: 80px;
      border-radius: 100%
    }
  }

  &__block {
    @include flex(column, flex-start, flex-start);
    margin-left: $space-md;

    &__label {
      font-size: $font-size-xl;
      font-weight: 500;
    }

    &__info {
      font-size: $font-size-md;
      color: $color-neutral;

      &.error {
        color: $color-danger;
      }
    }


    & > label {
      display: block;
      color: $color-primary;
      font-weight: 500;
      font-size: $font-size-lg;
      margin-top: $space-xs;
    }
  }
}