.cart-btn {
    @include flex-inline(row, center, center);
    height: $space-xxl;

    &__input {
        height: 100%;
        width: $space-xxl;
        text-align: center;
        border: 1px solid $color-border-active;
        border-top-left-radius: $border-radius-base;
        border-bottom-left-radius: $border-radius-base;

        &--disabled {
            cursor: not-allowed;
            background-color: $color-background-darker;
            border: 1px solid $color-border-disabled;
            color: $color-text-lighter;
        }
    }

    &__btn {
        background-color: $color-primary;
        height: 100%;
        color: $color-text-inverse;
        @include flex(row, center, center);
        position: relative;
        width: 104px;
        cursor: pointer;
        border-top-right-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
        border: 1px solid $color-border-active;

        &:not(.cart-btn__btn--disabled):hover {
            background-color: $color-primary-light;
        }

        &--disabled {
            cursor: not-allowed;
            background-color: $color-background-darker;
            border: 1px solid $color-border-disabled;
            color: $color-text-lighter;
        }

        .badge {
            position: absolute;
            top: -15px;
            right: -5px;
        }

        i {
            margin-left: $space-xs;
        }
    }
}