.badge {
  // font-size: $font-size-sm;
  // line-height: 13px;
  // font-weight: bold;
  // color: $color-text-inverse;
  // border-radius: 11px;
  // padding: $space-xxs /$space-xs;
  // display: inline-block;
  font-size: 10px;
  color: #fff;
  border-radius: 12px;
  border: 2px solid #fff;
  padding: 4px 8px;
  font-weight: 700;
  display: inline-block;
  cursor: default;
  text-transform: capitalize;

  &--success {
    background-color: $color-success;
  }

  &--warning {
    background-color: $color-warning;
  }

  &--danger {
    background-color: $color-danger;
  }

  &--neutral {
    background-color: $color-neutral;
  }

  &--purple {
    background-color: $color-purple;
  }

  &--info {
    background-color: $color-info;
  }

  &--teal {
    background-color: $color-teal;
  }

  &--yellow {
    background-color: $color-yellow;
  }

  &--magenta {
    background-color: $color-magenta;
  }

  &--black {
    background-color: black;
  }
}
