.lost-sale {
    position: relative;

    &__btn {
        width: $space-xxl;
        height: $space-xxl;
        border-radius: $border-radius-base;
        background-color: $color-danger;
        @include flex(row, center, center);
        color: $color-icon-inverse;
        cursor: pointer;

        &:hover {
            background-color: $color-danger-light;
        }
    }

    &__list {
        position: absolute;
        left: 0;
        top: calc(100% + #{$space-xs});
        background-color: $color-background;
        z-index: 1001;
        border: 1px solid $color-border;
        border-radius: $border-radius-base;
        padding: $space-xs 0;
        box-shadow: $box-shadow-lighter;
        min-width: 186px;
    }

    &__backdrop {
        background: transparent;
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1000;
    }

    &__item {
        padding: $space-xs $space-md;
        cursor: pointer;
        font-size: $font-size-md;
        text-align: left;

        &:hover {
            background-color: $color-danger;
            color: $color-text-inverse;
        }

        &--active {
            background-color: $color-danger;
            color: $color-text-inverse;
        }
    }

    &__comment {
        border-radius: $border-radius-base;
        border: 1px solid $color-border;
        width: 100%;
        height: 150px;
        padding: $space-md;
        resize: none;
    }
}