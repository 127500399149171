.icon {
    position: relative;
    display: block;
    vertical-align: middle;
    
    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--reverse {
        transform: scaleX(-1);
    }

    &--dark {
        color: $color-icon;
    }

    &--neutral {
        color: $color-neutral;
    }

    &--lighter {
        color: $color-icon-lighter;
    }

    &--light {
        color: $color-icon-inverse;
    }

    &--active {
        color: $color-icon-active;
    }

    &--danger {
        color: $color-danger;
    }
}