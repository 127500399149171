.catalogue {
  height: 100%;
  padding: $space-xxl;
  @include flex(column, center, flex-start);
  max-width: 1280px;
  width: 100%;
  transition: all 1s ease-in;

  &--visible {
    left: 0;
  }
}


.search-block {
  @include flex(column, center, space-between);
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 24px !important;
}

.custom-loader-background {
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
}