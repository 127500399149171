// Generate padding & margin classes

$sizes: (
  0: 0,
  xxs: $space-xxs,
  xs: $space-xs,
  sm: $space-sm,
  md: $space-md,
  lg: $space-lg,
  xl: $space-xl,
  xxl: $space-xxl,
  xxxl: $space-xxl,
  auto: auto
);

@each $size, $value in $sizes {
  .gap-#{$size} {
    gap: $value;
  }

  // Paddings
  .p-#{$size} {
    padding: $value;
  }

  .px-#{$size} {
    padding-right: $value;
    padding-left: $value;
  }

  .py-#{$size} {
    padding-top: $value;
    padding-bottom: $value;
  }

  .pt-#{$size} {
    padding-top: $value;
  }

  .pb-#{$size} {
    padding-bottom: $value;
  }

  .pr-#{$size} {
    padding-right: $value;
  }

  .pl-#{$size} {
    padding-left: $value;
  }

  // Margins
  .m-#{$size} {
    margin: $value;
  }

  .mx-#{$size} {
    margin-right: $value;
    margin-left: $value;
  }

  .my-#{$size} {
    margin-top: $value;
    margin-bottom: $value;
  }

  .mt-#{$size} {
    margin-top: $value;
  }

  .mb-#{$size} {
    margin-bottom: $value;
  }

  .mr-#{$size} {
    margin-right: $value;
  }

  .ml-#{$size} {
    margin-left: $value;
  }

  // Negative Margins
  .-m-#{$size} {
    margin: -$value;
  }

  .-mx-#{$size} {
    margin-right: -$value;
    margin-left: -$value;
  }

  .-my-#{$size} {
    margin-top: -$value;
    margin-bottom: -$value;
  }

  .-mt-#{$size} {
    margin-top: -$value;
  }

  .-mb-#{$size} {
    margin-bottom: -$value;
  }

  .-mr-#{$size} {
    margin-right: -$value;
  }

  .-ml-#{$size} {
    margin-left: -$value;
  }
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.flex-inline {
  display: inline-flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.fw-bold {
  font-weight: bold;
}

.fw-medium {
  font-weight: 500;
}

.h-fit-content {
  height: fit-content;
}
.w-fit-content {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-33 {
  width: 33.333333%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-10 {
  width: 10%;
}

.h-full {
  height: 100%;
}

.h-half {
  height: 50%;
}

.white-space {
  white-space: nowrap;
}

.lowercase {
  text-transform: lowercase;
}
.uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}

.hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}

.inline-block-w100 {
  width: 100%;
  display: inline-block;
}

.border-dashed {
  border-width: 2px;
  border-style: dashed;
}

// todo - delete or keep this classname, here and all over project's components/pages
// .card-list__container {
// border: 1px solid $color-border;
// background-color: $color-background-dark;
// border-radius: $border-radius-base;
// }

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
