.notifications {
  background-color: transparent;
  border: 0;
  &__tab-content {
    background-color: $color-background;
    border: 1px solid $color-border;
    border-top: 0;
    .box__header {
      min-height: auto;
    }
    .notification-item {
      text-align: left;
      border: none;
      background-color: transparent;
      font-size: 14px;
      cursor: pointer;
      // position: relative;
      &__title {
        line-height: 1.4;
      }
      &__time {
        margin-top: 6px;
      }
    }
  }
}
.notifications-dropdown {
  .dropdown__list {
    min-width: 360px;
    .notification-item {
      &__link {
        color: #2569a1;
      }
      &__avatar {
        width: 40px;
        height: 40px;
      }
      &__description {
        line-height: 1.4;
      }
    }
  }
}
