@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/iconmoon/icomoon.eot?wovifm');
  src: url('../../fonts/iconmoon/icomoon.eot?wovifm#iefix') format('embedded-opentype'),
    url('../../fonts/iconmoon/icomoon.ttf?wovifm') format('truetype'),
    url('../../fonts/iconmoon/icomoon.woff?wovifm') format('woff'),
    url('../../fonts/iconmoon/icomoon.svg?wovifm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activate:before {
  content: "\e900";
}
.icon-edit-fill:before {
  content: "\e901";
}
.icon-wrench:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-folder:before {
  content: "\e904";
}
.icon-upload-outlined:before {
  content: "\e905";
}
.icon-empty-files:before {
  content: "\e906";
}
.icon-folder-arrow-outlined:before {
  content: "\e907";
}
.icon-grid-view:before {
  content: "\e908";
}
.icon-sort-down:before {
  content: "\e909";
}
.icon-send-outline:before {
  content: "\e90a";
}
.icon-empty-folder:before {
  content: "\e90b";
}
.icon-user-shield:before {
  content: "\e90c";
}
.icon-arrow-left1:before {
  content: "\e90d";
}
.icon-arrow-right:before {
  content: "\e90e";
}
.icon-images1:before {
  content: "\e90f";
}
.icon-key:before {
  content: "\e910";
}
.icon-poll:before {
  content: "\e911";
}
.icon-sliders-h:before {
  content: "\e912";
}
.icon-user-cog:before {
  content: "\e913";
}
.icon-product-management:before {
  content: "\e914";
}
.icon-feather-radio:before {
  content: "\e915";
}
.icon-material-gps:before {
  content: "\e916";
}
.icon-deactivate:before {
  content: "\e917";
}
.icon-preview:before {
  content: "\e918";
}
.icon-suspend:before {
  content: "\e919";
}
.icon-checkmark:before {
  content: "\e91a";
}
.icon-drag:before {
  content: "\e91b";
}
.icon-exclamation-circle:before {
  content: "\e91c";
}
.icon-info-circle:before {
  content: "\e91d";
}
.icon-plus:before {
  content: "\e91e";
}
.icon-search:before {
  content: "\e91f";
}
.icon-signout-alt:before {
  content: "\e920";
}
.icon-times:before {
  content: "\e921";
}
.icon-upload:before {
  content: "\e922";
}
.icon-trash-delete:before {
  content: "\e923";
}
.icon-edit-filled:before {
  content: "\e924";
}
.icon-media-horn:before {
  content: "\e925";
}
.icon-banking-details:before {
  content: "\e926";
}
.icon-bar-chart:before {
  content: "\e927";
}
.icon-cart:before {
  content: "\e928";
}
.icon-close:before {
  content: "\e929";
}
.icon-company-details:before {
  content: "\e92a";
}
.icon-contacts:before {
  content: "\e92b";
}
.icon-currency:before {
  content: "\e92c";
}
.icon-customers:before {
  content: "\e92d";
}
.icon-help:before {
  content: "\e92e";
}
.icon-home:before {
  content: "\e92f";
}
.icon-logout:before {
  content: "\e930";
}
.icon-pricing:before {
  content: "\e931";
}
.icon-stock:before {
  content: "\e932";
}
.icon-suppliers:before {
  content: "\e933";
}
.icon-edit-pan:before {
  content: "\e934";
}
.icon-car-front:before {
  content: "\e935";
}
.icon-catalogue:before {
  content: "\e936";
}
.icon-catalogue-defaults:before {
  content: "\e937";
}
.icon-cog:before {
  content: "\e938";
}
.icon-cogs:before {
  content: "\e939";
}
.icon-communication:before {
  content: "\e93a";
}
.icon-compare:before {
  content: "\e93b";
}
.icon-dashboard:before {
  content: "\e93c";
}
.icon-dots:before {
  content: "\e93d";
}
.icon-information:before {
  content: "\e93e";
}
.icon-logout1:before {
  content: "\e93f";
}
.icon-preferences1:before {
  content: "\e940";
}
.icon-reset:before {
  content: "\e941";
}
.icon-shopping-cart:before {
  content: "\e942";
}
.icon-statistics:before {
  content: "\e943";
}
.icon-style:before {
  content: "\e944";
}
.icon-user-circle:before {
  content: "\e945";
}
.icon-user-friends:before {
  content: "\e946";
}
.icon-user-plus:before {
  content: "\e947";
}
.icon-back-arrow:before {
  content: "\e948";
}
.icon-branch:before {
  content: "\e949";
}
.icon-calendar:before {
  content: "\e94a";
}
.icon-cup:before {
  content: "\e94b";
}
.icon-forklift:before {
  content: "\e94c";
}
.icon-garage:before {
  content: "\e94d";
}
.icon-magnifier:before {
  content: "\e94e";
}
.icon-manage-sections:before {
  content: "\e94f";
}
.icon-plum-motor-factor:before {
  content: "\e950";
}
.icon-uniE91E:before {
  content: "\e951";
}
.icon-uniE91F:before {
  content: "\e952";
}
.icon-uniE920:before {
  content: "\e953";
}
.icon-User-tie:before {
  content: "\e954";
}
.icon-bell-active:before {
  content: "\e955";
}
.icon-Basket:before {
  content: "\e956";
}
.icon-Hamburger:before {
  content: "\e957";
}
.icon-home1:before {
  content: "\e958";
}
.icon-lock:before {
  content: "\e959";
}
.icon-mail-arrow:before {
  content: "\e95a";
}
.icon-User:before {
  content: "\e95b";
}
.icon-compare-new:before {
  content: "\e95c";
}
.icon-book:before {
  content: "\e95d";
}
.icon-uniE92C:before {
  content: "\e95e";
}
.icon-uniE92D:before {
  content: "\e95f";
}
.icon-uniE92E:before {
  content: "\e960";
}
.icon-Trash-alt:before {
  content: "\e961";
}
.icon-uniE933:before {
  content: "\e962";
}
.icon-email:before {
  content: "\e963";
}
.icon-uniE935:before {
  content: "\e964";
}
.icon-uniE936:before {
  content: "\e965";
}
.icon-uniE937:before {
  content: "\e966";
}
.icon-uniE939:before {
  content: "\e967";
}
.icon-Exclamation-Triangle:before {
  content: "\e968";
}
.icon-Filter:before {
  content: "\e969";
}
.icon-heart:before {
  content: "\e96a";
}
.icon-orders-2:before {
  content: "\e96b";
}
.icon-Theme:before {
  content: "\e96c";
}
.icon-Trash:before {
  content: "\e96d";
}
.icon-uniE944:before {
  content: "\e96e";
}
.icon-uniE945:before {
  content: "\e96f";
}
.icon-uniE948:before {
  content: "\e970";
}
.icon-uniE94B:before {
  content: "\e971";
}
.icon-car-side:before {
  content: "\e972";
}
.icon-download:before {
  content: "\e973";
}
.icon-exclamation-cog:before {
  content: "\e974";
}
.icon-Slider-Arrow1:before {
  content: "\e976";
}
.icon-Trash-icon:before {
  content: "\e977";
}
.icon-bell-neutral:before {
  content: "\e978";
}
.icon-chat:before {
  content: "\e979";
}
.icon-edit-outlined:before {
  content: "\e97a";
}
.icon-image:before {
  content: "\e97b";
}
.icon-images:before {
  content: "\e97c";
}
.icon-file-image:before {
  content: "\e97d";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-hide:before {
  content: "\e9d1";
}