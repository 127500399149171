* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  text-decoration: none;
  list-style: none;
  font-family: 'Inter', sans-serif;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
