.dialog {
  display: none;
  //overflow: hidden;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: fadeInOpacity 0.25s ease-in-out forwards;

  &--open {
    display: block;
  }

  &__backdrop {
    @extend .overlay;

    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    background-color: transparentize($color: $color-secondary, $amount: 0.45);
  }

  &__dialog {
    position: fixed;
    z-index: 1100;
    width: 100%;
    max-width: calc(100% - #{$space-md * 2});
    background-color: $color-background;
    box-shadow: $box-shadow-dark;
    border-radius: $border-radius-base;

    &--xxs {
      width: 304px;
    }

    &--xs {
      width: 370px;
    }

    &--sm {
      width: 500px;
    }

    &--smd {
      width: 560px;
    }

    &--md {
      width: 800px;
    }

    &--lg {
      width: 1100px;
    }

    &--xl {
      width: 1300px;
    }
    &--xxl {
      width: 1680px;
    }
  }

  &__close {
    position: relative;
    color: $color-text-light;
    font-size: $font-size-lg;
    cursor: pointer;
    background-color: transparent;
    border: none;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 28px;
      height: 28px;
      border-radius: 100%;
    }

    .icon {
      color: $color-icon-lighter;
    }

    &:hover {
      &::before {
        background-color: #d9d9d940;
      }

      .icon {
        color: $color-icon;
      }
    }
  }

  &__header {
    padding: $space-lg $space-lg 0 $space-lg;
    background-color: $color-background;
    @include flex(row, center, space-between);

    &__content {
      @include flex(row, center, flex-start);
    }

    &__icon {
      display: block;
      color: $color-background;
      font-size: 40px;
      margin-right: $space-md;
    }
  }

  &__content {
    height: 100%;
    flex-grow: 1;
    overflow: auto;
  }

  &__description {
    white-space: pre-line; // TODO -> test it
    font-weight: 400;
    line-height: 21px;
    padding: $space-sm $space-lg $space-lg $space-lg;
  }

  &__title {
    font-size: $font-size-xl;
    color: $color-text;
    font-weight: 500;
  }

  &__actions {
    @include flex(row, center, flex-end);
    padding: 0 $space-lg $space-lg $space-lg;
    &-left-section {
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--dark {
    .dialog__dialog {
      background-color: $color-background-dark;
    }
  }

  // Alignments
  &-alignment {
    &--center {
      .dialog {
        &__dialog {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          max-height: 90%;
          overflow: auto;
        }
      }
    }

    &--right,
    &--left {
      .dialog {
        &__dialog {
          top: 0;
          height: 100vh;
          max-height: 100vh;
          overflow: hidden;
          display: flex;
          flex-direction: column;
        }

        //&__content {
        //  height: 100%;
        //  flex-grow: 1;
        //  overflow: auto;
        //}

        &__actions {
          border-top: 1px solid $color-border;
        }
      }
    }

    &--left {
      .dialog {
        &__dialog {
          left: 0;

          transform: translateX(-100%);
          animation: appearFromLeft 0.3s ease-in-out forwards;
        }
      }
    }

    &--right {
      .dialog {
        &__dialog {
          right: 0;

          transform: translateX(100%);
          animation: appearFromRight 0.3s ease-in-out forwards;
        }
      }
    }
  }

  // Types

  &--modal {
    .dialog__header {
      padding: $space-lg;
      border-bottom: 1px solid $color-border;
    }

    .dialog__close {
      //padding: $space-md;

      &:hover {
        color: $color-text;
      }
    }

    .dialog__actions {
      padding: $space-md;
    }
  }

  $dialog-types: (
    info: $color-primary,
    success: $color-success,
    warning: $color-warning,
    danger: $color-danger
  );

  @each $color, $value in $dialog-types {
    &--#{$color} {
      .dialog__header__icon {
        .icon {
          color: $value;
        }
      }
    }
  }

  // States

  &.dialog--is-closing {
    animation: fadeOutOpacity 0.25s ease-in-out forwards;

    &.dialog-alignment {
      &--center {
        .dialog {
          &__dialog {
            animation: fadeOutOpacity 0.25s ease-in-out forwards;
          }
        }
      }

      &--right {
        .dialog {
          &__dialog {
            animation: disappearToRight 0.3s ease-in-out forwards;
          }
        }
      }

      &--left {
        .dialog {
          &__dialog {
            animation: disappearToLeft 0.3s ease-in-out forwards;
          }
        }
      }
    }
  }
}

@keyframes appearFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes disappearToRight {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes appearFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes disappearToLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
