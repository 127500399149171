.account-mirror-sidebar-content-wrapper {
  padding: 24px;
}

.account-mirror-sidebar-content-fields {
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 24px;
}

.account-mirror-view-content-wrapper {
  background-color: #8e94a5;
  padding: 24px;
}

.account-mirror-view-content-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.account-mirror-container {
  height: 100%;
  @include flex(column, center, flex-start);
  max-width: 1280px;
  width: 100%;
  transition: all 1s ease-in;
}

.account-mirror-container-body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
}

.account-mirror-title {
  color: white;
}

.account-mirror-title-and-icon {
  display: flex;
  align-items: center;
  gap: 8px;
}