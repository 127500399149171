.undo {
    width: 350px;
    border-radius: $border-radius-base;
    border: 2px solid $color-border-active;
    @include flex(row, center, flex-end);
    padding: $space-md;
    margin-top: $space-lg;

    &__title {
        margin-right: auto;
    }

    &__link {
        color: $color-primary;
        margin-right: $space-md;
        font-weight: bold;
        cursor: pointer;
    }

    &__close {
        color: $color-icon-lighter;
        cursor: pointer;
    }
}