.call-btn {
    @include flex-inline(row, center, center);
    width: 40px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: #fff;
    background-color: #e6a117;
    cursor: pointer;

    &:hover {
        background-color: #ffb319;
    }

    i {
        margin-left: $space-xs;
    }
}