.tabs {
  .tabs__list {
    @include flex(row, flex-end, flex-start);
    border-bottom: 1px solid #D9D9D9;
    overflow-x: auto;
    overflow-y: hidden;

    .tab__link, .tab__button {
      cursor: pointer;
      display: inline-block;
      font-weight: 500;
      outline: none;
      white-space: nowrap;
      border: none;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

  &.v-1 {
    .tabs__list {

      .tab {
        position: relative;
        margin-right: $space-xxs;
        margin-bottom: -1px;

        .tab__link, .tab__button {
          color: $color-text;
          padding: $space-sm $space-md;
          background-color: $color-background;
          border-radius: $border-radius-base $border-radius-base 0 0;
          border: 1px solid $color-border;

          &:not([disabled]) {
            &:not(.tab--active):hover {
              background-color: transparentize($color: $color-background, $amount: .25);
            }
          }

          &.tab--active {
            border-bottom: 1px solid $color-background;

            &::before {
              content: '';
              position: absolute;
              z-index: 1;
              width: 100%;
              height: 3px;
              top: 0;
              left: 0;
              background-color: $color-primary;
              border-top-left-radius: $border-radius-base;
              border-top-right-radius: $border-radius-base;
            }
          }
        }
      }
    }

    .tabs__panels {
      border: 1px solid $color-border;
      border-top: none;
      background-color: $color-background;
      padding: $space-md $space-sm;
      border-bottom-left-radius: $border-radius-base;
      border-bottom-right-radius: $border-radius-base;
    }
  }

  &.v-2 {
    .tabs__list {
      margin-bottom: $space-lg;

      .tab {
        padding-right: $space-xxl;

        .tab__link, .tab__button {
          position: relative;
          padding-bottom: $space-xs;
          color: $color-text-lighter;
          background-color: transparent;

          &:not([disabled]) {
            &:not(.tab--active):hover {
              color: $color-text;
            }
          }

          &.tab--active {
            color: $color-primary;

            &::before {
              content: '';
              position: absolute;
              z-index: 1;
              width: 100%;
              height: 3px;
              bottom: -2px;
              left: 0;
              background-color: $color-primary;
            }
          }
        }
      }
    }
  }
}

