.preview-dialog {
  &__content {
    background-color: $color-background-dark;
    min-height: 100%;
    .list-card__body {
      .list-card__title__primary {
        font-size: $font-size-lg;
      }
      .table-details {
        table {
          td {
            font-size: $font-size-lg;
          }
          td:first-of-type {
            max-width: 150px;
            min-width: 150px;
            span {
              white-space: normal;
            }
          }
        }
      }
      &:first-child {
        .list-card__title__primary {
          font-size: $font-size-xl;
        }
        .preview-heading-info {
          td {
            font-size: $font-size-md;
            padding-top: $space-xxs;
          }
          td.table-details__value--website {
            color: $color-primary;
          }
        }
        .badge {
          top: 0;
          bottom: unset;
        }
      }
    }
  }
  .dialog__actions {
    justify-content: flex-start;
  }
}
.preview-media-dialog {
  .dialog__dialog {
    max-width: 580px;
  }
}
