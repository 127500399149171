$color-primary: #2569a1;
$color-primary-dark: #1f5887;
$color-primary-light: #2b79ba;
$color-primary-darker: $color-primary;
$color-primary-lighter: #318ad4;

$color-secondary: #283555;
$color-secondary-light: #34466e;
$color-secondary-lighter: #405687;

$color-text: #333333;
$color-text-light: #666666;
$color-text-lighter: #999999;
$color-text-disabled: #b3b3b3;
$color-text-active: $color-primary;
$color-text-inverse: #ffffff;

$color-icon: #333333;
$color-icon-light: #666666;
$color-icon-lighter: #999999;
$color-icon-inverse: #ffffff;
$color-icon-active: $color-primary;
$color-icon-disabled: #b3b3b3;

$color-background: #ffffff;
$color-background-dark: #f5f5f5;
$color-background-darker: #d9d9d9;
$color-background-darkest: #cccccc;
$color-background-disabled: #f2f2f2;
$color-background-inverse: $color-secondary;

$color-border: #d9d9d9;
$color-border-dark: #cccccc;
$color-border-disabled: #f2f2f2;
$color-border-inverse: #ffffff;
$color-border-active: $color-primary;

$color-success: #2ba125;
$color-success-dark: #269936;
$color-success-light: #33cc47;
$color-warning: #e6a117;
$color-warning-dark: #cc8f14;
$color-warning-light: #ffb319;
$color-danger: #e61e2e;
$color-danger-dark: #cc1b29;
$color-danger-light: #ff2133;
$color-purple: #4e25a1;

$color-info: #254ea1;
$color-teal: #25a19b;
$color-yellow: #edc607;
$color-magenta: #a125a1;

$color-neutral: #808080;

$color-background-reg-input: #f2de3f;
$color-border-reg-input: #f2de3f;

$space-xxxs: 2px;
$space-xxs: 4px;
$space-xs: 8px;
$space-sm: 12px;
$space-md: 16px;
$space-lg: 24px;
$space-xl: 32px;
$space-xxl: 40px;
$space-xxxl: 64px;

$font-size-xs: 8px;
$font-size-sm: 10px;
$font-size-md: 12px;
$font-size-lg: 14px;
$font-size-xl: 16px;
$font-size-xxl: 18px;
$font-size-xxxl: 20px;

$line-height-xs: 1.25em;
$line-height-sm: 1.35em;
$line-height-md: 1.5em;
$line-height-lg: 1.6em;
$line-height-xl: 1.75em;
$line-height-xxl: 2em;

$border-radius-base: 3px;

$box-shadow-lighter: 0 3px 7px rgba(0, 0, 0, 0.1);
$box-shadow-light: 0 5px 15px rgba(0, 0, 0, 0.15);
$box-shadow-dark: 0 15px 25px rgba(0, 0, 0, 0.25);

$box-shadow-xs: 0px 1px 2px rgba(0, 0, 0, 0.05);
$box-shadow-sm: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 1px 2px -1px rgba(0, 0, 0, 0.08);
$box-shadow-md: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1);
$box-shadow-lg: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
$box-shadow-xl: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 8px 10px -6px rgba(0, 0, 0, 0.1);
$box-shadow-xxl: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);

$table-cell-height: 48px;

$grid-columns: 12;

$checkbox-size: 20px;
