/* QuantityChanged.css */

.dialog-body {
  padding: 20px;
  text-align: left;
}

.dialog-body h3 {
  color: #333;
  font-size: 1.5em;
}

.dialog-body ul {
  list-style: none;
  padding: 0;
}

.dialog-body li {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.dialog-body li:last-child {
  border-bottom: none;
}

.dialog-body strong {
  font-weight: bold;
  margin-right: 5px;
}

/* Optional: Add some styles to improve readability and aesthetics */
.dialog-body {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dialog-body hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #ddd;
}

.success-modal-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 36px 0px;
}
