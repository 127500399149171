.list-card {
  height: 100%;
  background-color: $color-background;
  border: 1px solid $color-border;
  padding: $space-md;
  // border-radius: $border-radius-base;
  border-radius: 13px;
  box-shadow: $box-shadow-sm;

  &__header {
    position: relative;
    margin-bottom: $space-xs;
    padding-right: 35px;
    @include flex(row, center, flex-start);
  }

  &__avatar {
    width: 40px;
    height: 40px;
    margin-right: $space-md;
    border-radius: 100%;
    flex-shrink: 0;

    &--img {
      object-fit: cover;
    }

    &--text {
      @include flex(row, center, center);
      color: $color-background;
      font-size: $font-size-xxxl;
      font-weight: bold;
      background-color: $color-primary;
      text-transform: uppercase;
    }

    &--icon {
      .icon {
        color: $color-icon-disabled;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 100%;

    &__primary,
    &__secondary {
      display: -webkit-box;
      
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__primary {
      font-size: $font-size-xl;
      //line-height: 24px;
      font-weight: 500;
    }

    &__secondary {
      font-size: $font-size-md;
      color: $color-primary;
      font-weight: 500;

      &__label {
        font-weight: 400;
        color: $color-text-lighter;
      }
    }

    .typography {
      &.success {
        color: $color-success;
      }

      &.danger {
        color: $color-danger;
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 0;
    right: 0;

    & > button {
      border: none;
      border-radius: 100%;
      background-color: transparent;
      cursor: pointer;
      min-width: 28px;
      min-height: 28px;
      flex-shrink: 0;

      .icon {
        margin-left: auto;
        margin-right: auto;
      }

      &:hover {
        background-color: #eee;

        span.icon {
          color: $color-icon;
        }
      }
    }

    &-item {
      width: 152px;
      display: flex;
      align-items: center;

      .icon {
        margin-right: $space-md;
      }

      &.red {
        color: $color-danger;

        .icon {
          color: $color-danger;
        }
      }
    }
  }

  &__body {
    position: relative;

    .badge {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  // Types
  &--supplier,
  &--branch,
  &--customer {
    .list-card__title {
      &__primary {
        color: $color-primary;
        font-weight: bold;
      }

      &__secondary--customer {
        font-size: $font-size-md;
        color: $color-text-lighter;

        span:nth-child(2) {
          font-weight: 500;
          color: $color-text;
        }
      }
    }
  }
}
