.signin {
  height: 100%;
  padding: $space-xxl;
  @include flex(column, center, flex-start);
  width: 340px;
  background-color: $color-background;
  box-shadow: $box-shadow-light;
  transition: all 1s ease-in;

  &--visible {
    left: 0;
  }

  form {
    margin: auto;
    width: 100%;

    button {
      width: 100%;
      height: 48px;
      border-radius: $border-radius-base;
      border: none;
      cursor: pointer;
      color: $color-text-inverse;
      font-size: $font-size-xxl;
      font-weight: 700;
    }
  }
}

.languages-wrapper-login {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  span {
    font-size: large;
    border-right: 1px solid black;
    padding-right: 12px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    
    &:last-child {
      border: 0px;
      padding-right: 0px;
    }
  }
}