.orders-table-wrapper {
    display: flex;
    flex-direction: column;
}

.orders-header-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    
    width: 100%;
    margin-bottom: 24px;
}

.field__inner__orders {
    padding: 8px;
    border: 1px solid #dedede;
}

.react-datepicker__tab-loop {
    position: absolute;
    top: 0;
  }
  
  .react-datepicker__triangle {
    display: none !important;
  }