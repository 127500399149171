.snackbar-container {
  position: fixed;
  z-index: 2000;
  right: $space-xs;
  bottom: $space-xs;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.snackbar {
  //display: none;
  min-width: 150px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: $border-radius-base;
  box-shadow: 0 2px 4px #00000047;
  //overflow: hidden;
  height: 0;
  margin-top: 0;
  padding: 0 $space-md 0 $space-sm;
  transform: translate(calc(100% + #{$space-xs}));
  transition: 0.3s transform cubic-bezier(0.55, 0.06, 0.68, 0.19), 0.3s height ease-in-out, 0.3s margin ease-in-out, 0.3s padding ease-in-out;
  transition-delay: 0s, 0.3s, 0.3s, 0.3s;

  &--neutral {
    background-color: $color-neutral;
  }

  &--info {
    background-color: $color-primary;
  }

  &--success {
    background-color: $color-success;
  }

  &--warning {
    background-color: $color-warning;
  }

  &--danger {
    background-color: $color-danger;
  }

  &--visible {
    height: 50px;
    margin-top: $space-xs;
    padding: $space-xs $space-md;
    transform: translate(0);
    transition: 0.3s transform cubic-bezier(0.22, 0.61, 0.36, 1), 0.3s height ease-in-out, 0.3s margin ease-in-out, 0.3s padding ease-in-out;
    transition-delay: 0.3s, 0s, 0s, 0s;
  }

  &__close {
    position: relative;
    color: $color-text-light;
    font-size: $font-size-lg;
    cursor: pointer;
    background-color: transparent;
    border: none;
    margin-left: $space-lg;
    margin-right: -$space-xs;
    width: $space-xl;
    height: $space-xl;

    .icon {
      margin: auto;
      opacity: 0.7;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);

      border-radius: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -$space-xs;
      width: 1px;
      height: 100%;
      background-color: transparentize($color-background, 0.5);
    }

    &:hover {
      .icon {
        opacity: 1;
      }

      &::before {
        background-color: #d9d9d940;
      }
    }
  }

  &__body {
    height: 100%;
    //background-color: $color-background;
    @include flex(row, center, flex-start);

    &__icon {
      display: block;
      margin-right: $space-md;
      color: $color-text-inverse;
    }

    &__content {
      font-weight: 400;
      line-height: 21px;
      color: $color-text-inverse;
    }
  }
}