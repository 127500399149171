@keyframes progress {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

@keyframes progressBlur {
    0% {
        filter: blur(2px);
        -webkit-filter: blur(2px);
    }
    100% {
        filter: blur(0px);
        -webkit-filter: blur(0px);
    }
}