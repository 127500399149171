.pl-selectors {
    @include flex(row, center, space-between);
    margin-bottom: $space-lg;

    &__item {
        width: calc(50% - #{$space-sm});
    }

    &__selector {
        width: 100%;
    }

    &__label {
        margin-bottom: $space-xs;
        display: block;
    }
}