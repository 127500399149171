.setting-details {
  .input {
    margin-bottom: $space-md;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.company-stock-feed {
  .group {
    margin-bottom: $space-md;
  }
}

.catalogue-preferences {
  .container-fluid {
    padding: 0;
    .col:nth-child(2) {
      border-left: 1px solid $color-border;
    }
  }
  &-switch__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > span:first-of-type {
      color: $color-text;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .field {
      width: auto;
      &__container {
        margin-bottom: 0;
      }
      &__select {
        min-width: 181px;
        .field__inner {
          font-size: $font-size-lg;
        }
      }
    }
  }
}
.product-management-createtab-dialog {
  .product-management-createtab-dialog__content {
    form {
      .field__radio {
        label {
          margin-right: 0;
        }
      }
    }
  }
}
.catalogue-menegement-type-delete-dialog {
  .dialog__header {
    background-color: #eb3b3b;
    .dialog__title {
      color: $color-text-inverse;
    }
  }
  .catalogue-menegement-type-delete-dialog__content {
    p {
      line-height: 1.5;
    }
    .field {
      width: auto;
      @include flex(row, center, flex-start);
      label {
        width: 50%;
        font-size: $font-size-lg;
      }
      &__container {
        width: 50%;
        .field__inner {
          font-size: $font-size-lg;
        }
      }
    }
  }
}
.catalogue-menegement-type-add-dialog {
  .catalogue-menegement-type-add-dialog__content {
    form {
      .field__radio {
        label {
          margin-right: 0;
        }
      }
    }
  }
}
.price-level-account {
  .card-list__container {
    min-height: 358px;
    .checkbox-option {
      width: 100%;
      & > .icon.isopened {
        transform: rotate(90deg);
      }
      .typography {
        text-transform: uppercase;
      }
    }
  }
}

.product-management,
.catalogue-menegement-type-edit-dialog__content {
  .card-list__container {
    .checkbox-option {
      width: 100%;
      & > .icon.isopened {
        transform: rotate(90deg);
      }
      img {
        width: 41px;
      }
      .typography {
        text-transform: uppercase;
      }
    }

    &--dnd-section-lvl2,
    &--dnd-section-lvl3 {
      border-radius: 0;
      border: 0;
      border-left: 1px solid $color-border;
      .typography {
        text-transform: capitalize !important;
      }
    }
    &--dnd-section-lvl1 > div > div:last-of-type .card-list__container--dnd-section-lvl2,
    &--dnd-section-lvl2 > div > div:last-of-type .card-list__container--dnd-section-lvl3 {
      border: 0;
      position: relative;
      &::after {
        content: '';
        height: calc(100% - 25px);
        width: 1.1px;
        background-color: $color-border;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    &--dnd-section-lvl1 > div > div:last-of-type .card-list__container--dnd-section-lvl2 {
      &.isopened::after {
        height: calc(100% - 160px);
      }
    }

    &--dnd-section-lvl1 > div > div:last-of-type > .checkbox-option,
    &--dnd-section-lvl2 > div > div:last-of-type > div,
    &--dnd-section-lvl3 > div > div:last-of-type > .checkbox-option,
    &--dnd-section-lvl3 > div > div:last-of-type > .divider {
      margin-bottom: 0;
    }
    // &--dnd-section-lvl1 > div > div:last-of-type > .checkbox-option {
    //     margin-top: $space-xs;
    // }
  }
}

.manage-customer-types,
.pricing-levels,
.catalogue-management {
  .card-list__container--checkbox-list {
    max-height: 497px;
    padding-bottom: 88px;
  }
  .list-card__dropdown {
    top: auto;
    right: $space-xs;
  }
}

.product-management {
  $image-controller-height: 234px;

  padding: 0;
  background-color: #f5f5f5;
  border: none;

  &__search-button {
    line-height: 14px;
  }

  &__dialog-description {
    padding-left: 77px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 20px;
    line-height: 20px;
  }
  &__form-wrapper {
    background: $color-background-dark;
    border: 1px solid $color-background-darker;
    border-radius: 3px;
    padding: $space-md;

    &__container {
      display: flex;
      flex-wrap: wrap;
    }
    &__card {
      width: calc(33.33% - 16px + 5px);
      margin-right: $space-md;
      margin-top: $space-md;

      &:nth-child(3n) {
        margin-right: 0 !important;
      }

      &:nth-child(-n + 3) {
        margin-top: 0;
      }
    }

    &__dropzone {
      height: $image-controller-height;
      padding: 0 !important;
      .dropzone__text {
        padding: 39px 0;
      }
    }
  }
  &__image-controller {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 3px;
    border: 1px solid $color-background-darker;
    height: $image-controller-height;

    &__image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    &__divider {
      margin-top: auto;
    }
  }

  &__kit-builder {
    &__edit {
      background-color: transparent;
      border: 0;
      padding: 0;
    }
    &__product-included {
      &__button {
        all: unset;
        cursor: pointer;
        background-color: $color-background-dark;
        color: $color-text-lighter;
        border-radius: 50px;
        padding: 8px;
        width: 12px;
        height: 12px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &:first-child {
          margin-right: $space-md;
        }
        &:last-child {
          margin-left: $space-md;
        }
      }
    }

    &__kit-pricing {
      &__percent {
        color: #e6a017;
        white-space: nowrap;
      }

      &__field {
        position: relative;

        &__input {
          margin-bottom: 0;

          .field__container {
            margin-bottom: 0;
          }

          &--focused {
            .field__container {
              input {
                padding-left: 20px;
              }
            }
          }
        }

        &__sign {
          display: none;

          &--focused {
            display: inline;
            position: absolute;
            z-index: 1;
            padding-left: 10px;
          }
        }
      }
    }
  }
  &__edit-wrapper {
    .dialog__content {
      background-color: $color-background-dark;
    }
    &__form-wrapper {
      border: 1px solid $color-background-darker;
      border-radius: 3px;
      background-color: white;
    }
  }
}

.catalogue-slider-image-card {
  width: 153px;
  height: 119px;
  border: 1px solid #dedede;
  border-radius: 3px;
  background: #fff;

  &:hover {
    box-shadow: 0px 3px 7px #0000001a;

    .catalogue-slider-image-card__trash {
      opacity: 1;
    }
  }

  &__image {
    height: 90px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
  }

  &__trash {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    background-color: rgba(88, 88, 88, 0.6) !important;
    justify-content: center;
    padding: 2px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    cursor: pointer;
    opacity: 0;

    &:hover {
      box-shadow: 0px 1px 3px rgb(207, 61, 61);
      background-color: rgba(207, 61, 61, 1) !important;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    height: 29px;

    .catalogue-slider-image-card__title {
      color: #000;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      max-height: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 50%;
      overflow: hidden;

      &::selection {
        background-color: transparent;
      }
    }
  }
}

.catalogue-image-slider-container {
  @include flex(row, flex-start, flex-start);
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 15px;
}
