.compare-btn {
    width: $space-xxl;
    height: $space-xxl;
    position: relative;
    @include flex(row, center, center);
    border: 1px solid $color-border-active;
    border-radius: $border-radius-base;
    background-color: $color-background;
    color: $color-icon-active;
    cursor: pointer;

    &:not(.compare-btn--active):hover {
        background-color: $color-background-dark
    }

    &--active {
        background-color: $color-primary;

        .compare-btn__check {
            @include flex(row, center, center);
        }
    }

    &__check {
        width: $space-lg;
        height: $space-lg;
        background-color: $color-success;
        color: $color-text-inverse;
        border-radius: 50%;
        position: absolute;
        border: 2px solid #fff;
        top: -15px;
        right: -12px;
        display: none;
        font-size: $font-size-xs;
    }
}