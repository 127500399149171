.dropdown {
  position: relative;
  display: inline-block;

  &__toggle {
    width: auto;
    border: none;
    background-color: #eee;
    color: #000;
    display: block;
    padding: 1rem;
    font-size: 1rem;
    transition: background-color 0.15s linear;
    cursor: pointer;
    text-decoration: none;
    text-align: left;
    box-sizing: border-box;

    &:hover {
      background-color: #e0e0e0;
    }
  }

  &__divider {
    margin: $space-xxs 0;
    //padding: $space-xxs 0;
    border: none;
    background-color: $color-border;
  }

  &__list {
    margin: 0;
    padding: $space-xs 0;
    position: absolute;
    min-width: 100%;
    //width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 5px 15px #0000001A;
    background-color: $color-background;
    border-bottom-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
    z-index: 1;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  &__section {
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    margin: $space-xxs 0;
    padding: $space-xxs 0;

    .dropdown__item {
      padding: $space-xs $space-xs $space-xs $space-md;
    }
  }

  &__item {
    cursor: default;
    display: block;
    list-style: none;
    margin: 0;
    width: 100%;
    padding: $space-xs $space-md;
    text-align: left;

    &--clickable {
      cursor: pointer;

      &:hover {
        background-color: $color-background-dark;
      }
    }
  }
}