.step-by-step {
  &__tabs {
    @include flex(row, stretch, space-between);
    border: 1px solid $color-border;
    background-color: $color-background;
    border-top-right-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;
    overflow: hidden;
  }

  &__item {
    position: relative;
    @include flex(row, center, flex-start);
    cursor: default;
    overflow: hidden;
    flex: 1 1 0;
    min-width: 0;
    padding: $space-sm $space-lg $space-sm $space-md;
    background-color: $color-background;

    &.step__tab--current {
      background-color: $color-primary;

      .step-by-step__item__index {
        color: $color-primary;
        background-color: $color-text-inverse;
      }

      .step-by-step__item__label {
        color: $color-text-inverse;
      }
    }

    &__index {
      @include flex(row, center, center);
      border-radius: 100%;
      color: $color-background;
      font-size: $font-size-lg;
      font-weight: bold;
      background-color: $color-icon;
      width: $space-lg;
      height: $space-lg;
      margin-right: $space-xs;
      flex-shrink: 0;
    }

    &__label {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
      color: $color-icon;
      font-size: $font-size-lg;
      font-weight: 500;
    }
  }

  &__step {
    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      right: 0;
      width: 100px;
      height: 37px;
      background-color: $color-background;
    }

    &::before {
      top: 0;
      transform: translate(50%, -50%) rotate(40deg);
      border-bottom: 1px solid $color-border;
    }

    &::after {
      bottom: 0;
      transform: translate(50%, 50%) rotate(-40deg);
      border-top: 1px solid $color-border;
    }

    &.step__tab--passed {
      background-color: $color-primary-dark;

      .step-by-step__item__index {
        color: $color-primary-dark;
        background-color: $color-text-inverse;
      }

      .step-by-step__item__label {
        color: $color-text-inverse;
      }

      &::before,
      &::after {
        background-color: $color-primary-dark;
      }
    }

    &.step__tab--previous {
      &::before,
      &::after {
        background-color: $color-primary;
      }
    }

    &:last-of-type {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  &__tab {
    &:hover {
      background-color: $color-primary;

      .step-by-step__item__index {
        color: $color-primary;
        background-color: $color-text-inverse;
      }

      .step-by-step__item__label {
        color: $color-text-inverse;
      }
    }
  }
}
