.breadcrumbs {

    &__list {
        height: 100%;
        @include flex(row, center, flex-start);
    }

    &__item {
        position: relative;
        margin-right: $space-xs;
        @include flex(row, center, flex-start);

        &:last-child {

            .breadcrumbs__link {
                color: $color-text;
                cursor: initial;
            }
        }

        &:not(:last-child) {

            .breadcrumbs__link {

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__link {
        color: $color-text-active;
    }
}