@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../fonts/Inter-Regular.woff2?v=3.19') format('woff2'),
    url('../../fonts/Inter-Regular.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../fonts/Inter-Medium.woff2?v=3.19') format('woff2'),
    url('../../fonts/Inter-Medium.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../fonts/Inter-SemiBold.woff2?v=3.19') format('woff2'),
    url('../../fonts/Inter-SemiBold.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../fonts/Inter-Bold.woff2?v=3.19') format('woff2'),
    url('../../fonts/Inter-Bold.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../../fonts/Inter-ExtraBold.woff2?v=3.19') format('woff2'),
    url('../../fonts/Inter-ExtraBold.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'UKNumberPlate';
  src: url('../../fonts/UKNumberPlate.eot');
  src: url('../../fonts/UKNumberPlate.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/UKNumberPlate.woff2') format('woff2'),
    url('../../fonts/UKNumberPlate.woff') format('woff'),
    url('../../fonts/UKNumberPlate.ttf') format('truetype'),
    url('../../fonts/UKNumberPlate.svg#UKNumberPlate') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-primary {
  color: $color-primary;
}

.text-danger {
  color: $color-danger-light;
}

$font-sizes: (
  xs: $font-size-xs,
  sm: $font-size-sm,
  md: $font-size-md,
  lg: $font-size-lg,
  xl: $font-size-xl,
  xxl: $font-size-xxl,
  xxxl: $font-size-xxxl,
  auto: auto
);

@each $size, $value in $font-sizes {
  .font-s-#{$size} {
    font-size: $value;
  }
}

$font-weights: (300, 400, 500, 700, 900, bold, auto);

@each $weight in $font-weights {
  .font-w-#{$weight} {
    font-weight: $weight;
  }
}
