.campaigns {
  .list-card--campaigns {
    .field {
      .control__checkbox {
        margin-right: $space-xs;
      }
    }
  }
  
  .list-card-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
}
