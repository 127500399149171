
.page__header {
  @include flex(row, center, space-between);
  height: 48px;
  border-bottom: 1px solid $color-border;
  background-color: $color-background;
  padding: 0 $space-lg;
}

.page {
  width: 100%;
  padding: $space-lg;

  &__sub__header {
    width: 100%;
    margin-bottom: $space-md;
    @include flex(row, center, space-between);

    &__title {
      font-weight: 500;
      color: $color-text;
      font-size: $font-size-xxl;
      text-transform: capitalize;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    padding: $space-lg;
    background-color: $color-background;
    border: 1px solid $color-border;
    min-height: 300px;

    &--full {
      border-radius: $border-radius-base;
      border-width: 1px;
    }

    &--top {
      border-top-right-radius: $border-radius-base;
      border-top-left-radius: $border-radius-base;
      border-width: 1px 1px 0 1px;
    }

    &--mid {
      border-width: 0 1px;
    }

    &--bottom {
      border-bottom-right-radius: $border-radius-base;
      border-bottom-left-radius: $border-radius-base;
      border-width: 0 1px 1px 1px;
    }
  }

}