.user {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: $space-xl;
    background-color: $color-border;
    left: -#{$space-lg};
    top: 50%;
    transform: translateY(-50%);
  }

  &__card {
    @include flex(row, center, flex-start);
    cursor: pointer;
    padding: 0 $space-sm;
    height: 63px;

    &:hover {
      background-color: $color-background-dark;
    }
  }

  &__avatar {
    margin-right: $space-sm;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }

  &__arrow {
    margin-left: $space-sm;
  }

  &__role {
    display: block;
    font-size: $font-size-md;
    color: $color-text-active;
  }

  &__name {
    display: block;
    font-weight: 500;
  }

  .dropdown__item:not(.user-dropdown-item) {

    @include flex(row, center, flex-start);
    padding: 0 $space-lg;
    min-height: 32px;
    color: $color-text;

    img {
      margin-left: $space-sm;
    }

    &:first-of-type {
      @include flex(column, flex-start, flex-start);
      padding: $space-md $space-lg;

      span {
        display: block;
        font-size: $font-size-md;
        color: $color-text-lighter;

        &:first-child {
          font-size: $font-size-xl;
          color: $color-text;
          font-weight: 500;
        }
      }
    }

    &:last-of-type {
      color: $color-danger
    }
  }

  .dropdown--open {

    .user__dropdown {
      display: block;
    }

    .user__card {
      background-color: $color-background-dark;
    }

    .user__arrow {
      transform: rotate(-180deg);
    }
  }

}