.note-bar {
  width: 100%;
  max-width: 100%;
  border-radius: $border-radius-base;
  border-style: solid;
  border-width: 1px;
  padding: $space-sm;
  opacity: 0;
  animation: fadeInOpacity 0.25s ease-in-out forwards;
  animation-delay: 0.1s;

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: calc(14px + (2 * #{$space-xs}));
  }

  &__icon {
    flex-shrink: 0;
    margin-right: $space-md;
  }

  &__content {
    font-size: $font-size-lg;
    line-height: 21px;
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: $font-size-lg;
    border-radius: 100%;
    padding: $space-xs;
    transform: translateY(-50%);

    .icon {
      color: $color-icon-lighter;
    }

    &:hover {
      .icon {
        color: $color-icon;
      }
    }
  }

  // Types

  $note-types: (
    info: $color-primary,
    success: $color-success,
    warning: $color-warning,
    danger: $color-danger
  );

  @each $size, $value in $note-types {
    &--#{$size} {
      background-color: transparentize($value, 0.85);
      border-color: transparentize($value, 0.7);

      .note-bar {
        &__icon .icon {
          color: $value;
        }

        &__content {
          color: $value;

          & > * {
            color: $value;
          }
        }

        &__close {
          .icon {
            color: $value;
          }

          &:hover,
          &:focus {
            background-color: transparentize($value, 0.9);
          }
        }
      }
    }
  }

  &.note-bar--is-closing {
    animation: fadeOutOpacity 0.25s ease-in-out forwards;
  }
}
