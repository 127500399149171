.pagination {
  display: flex;
  width: fit-content;
  border: 1px solid $color-border;
  border-radius: 3px;
  background: white;

  &__item {
    cursor: pointer;

    &__link {
      font-weight: 500;
      display: block;
      padding: 6px 12px;
    }

    &--previous {
      border-right: 1px solid $color-border;
    }

    &--next {
      border-left: 1px solid $color-border;
    }

    &--break {
      line-height: 0.7;
      font-weight: bolder;
    }

    &--active {
      background: $color-primary;
      color: white;
      pointer-events: none;
    }

    // this is for next button
    &:not(:last-child) {
      border-right: 1px solid $color-border;
    }

    // this is for items
    &:last-child {
      border-left: 0;
    }
  }

  .disabled {
    cursor: not-allowed;
    background-color: $color-background-dark;
  }
}
