.typography {

  //'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2'

  // Variants
  $typography-variants: (
          h1: (
                  size: $font-size-xxxl,
                  lineHeight: $line-height-xxl,
                  weight: 500,
                  color: $color-text
          ),
          h2: (
                  size: $font-size-xxl,
                  lineHeight: $line-height-xl,
                  weight: 500,
                  color: $color-text
          ),
          h3: (
                  size: $font-size-xl,
                  lineHeight: $line-height-xl,
                  weight: 500,
                  color: $color-text
          ),
          h4: (
                  size: $font-size-lg,
                  lineHeight: $line-height-lg,
                  weight: 500,
                  color: $color-text
          ),
    //h5: (
    //        size: $font-size-md,
    //        lineHeight: $line-height-md,
    //        weight: 400,
    //        color: $color-text
    //),
    //h6: (
    //        size: $font-size-sm,
    //        lineHeight: $line-height-sm,
    //        weight: 400,
    //        color: $color-text
    //),
    //subtitle1: (
    //        size: $font-size-sm,
    //        lineHeight: $line-height-sm,
    //        weight: normal
    //        color: $color-text
    //),
    //subtitle2: (
    //        size: $font-size-sm,
    //        lineHeight: $line-height-sm,
    //        weight: normal
    //        color: $color-text
    //),
          body1: (
                  size: $font-size-lg,
                  lineHeight: $line-height-lg,
                  weight: normal,
                  color: $color-text
          ),
          body2: (
                  size: $font-size-md,
                  lineHeight: $line-height-md,
                  weight: normal,
                  color: $color-text-light
          )
  );


  @each $variant, $value in $typography-variants {

    &--#{$variant} {
      font-size: map-get($value, size);
      font-weight: map-get($value, weight);
      line-height: map-get($value, lineHeight);
      color: map-get($value, color);
    }
  }


  // Align
  &__align--center {
    text-align: center;
  }

  &__align--right {
    text-align: right;
  }

  &__align--left {
    text-align: left;
  }

  // Other
  &__gutter-bottom {
    margin-bottom: $space-sm;
  }

  &--no-wrap {
    white-space: nowrap;
  }

  &--required {
    &::after {
      content: "*";
      color: #eb3b3b;
      margin-left: 3px;
    }
  }

  &--error-text-red {
    color: $color-danger;
  }
}