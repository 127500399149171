.box__header {
  @include flex(row, center, space-between);
  min-height: 34px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  font-size: 16px;
  width: 100%;
  text-transform: capitalize;
  font-size: 12px;

}
.block__subtitle {
  opacity: 0.5;
  display: block;
  margin-bottom: $space-xs;
  line-height: 1.2;
  font-weight: 400;
}

.block__content {
  margin-top: 16px;
  width: 100%;
}
