.cart-description-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  margin-bottom: 24px;
}

.cart-table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cart-part-number-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  
  width: 100%;
  margin-top: 8px;

  &--input {
    background-color: white;
  }
}

.cart-textarea-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  textarea {
    border: 1px solid #dedede;

    width: 350px;
    height: 100px;
    padding: 16px;
  }
}

.cart-details-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  margin: 24px 0px;

  @media (max-width: 480px) {
    flex-direction: column;
    .cart-textarea-wrapper {
      textarea {
        width: 100%;
        height: 100px;
      }
    }
    .cart-total {
      width: 100%;

      margin-top: 24px;
    }
  }
}
.cart-total {
  text-align: right;
  width: 30%;
}

.cart-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 20px;

  width: 100%;

  .control {
    &__checkbox {
      margin: 0;
    }
  }
}
