.navigation {
  height: 100%;
  padding-top: $space-xl;
  padding-bottom: $space-xs;

  &__list {
    @include flex(column, flex-start, flex-start);

    > .navigation__item {
      margin-bottom: $space-lg;

      &::after {
        content: '';
        width: calc(100% - 48px);
        height: 1px;
        background-color: $color-background-darker;
        position: absolute;
        bottom: 0;
        left: $space-lg;
      }

      &:last-child {

        &::after {
          display: none;
        }
      }
    }
  }

  &__item {
    margin-bottom: $space-xs;
    width: 100%;
    position: relative;

    &.inner {
      background-color: $color-background-dark;

      .navigation__item {
        position: relative;

        &:hover {
          .navigation__link {
            color: $color-primary;
          }

          .navigation__link::before {
            background-color: $color-primary;
          }
        }

        .navigation__link::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          top: 50%;
          left: 52px;
          transform: translate(-50%, -50%);
          border-radius: 100%;
          background-color: $color-text;
        }


      }
    }
  }

  &__label {
    padding: 0 $space-lg;
    font-size: $font-size-md;
    color: $color-text-lighter;
    text-transform: uppercase;
    margin-bottom: $space-xs;
  }

  &__link {
    color: $color-text;
    border: none;
    padding: 0 $space-lg;
    height: $space-xxl;
    cursor: pointer;
    background-color: transparent;
    width: 100%;
    @include flex(row, center, flex-start);

    .navigation__arrow {
      position: relative;
      margin-left: auto;

      &::after {
        content: "";
        position: absolute;
        border-top: 2px solid black;
        border-right: 2px solid black;
        width: 6px;
        height: 6px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(135deg);
      }
    }

    &--active {
      background-color: $color-background-dark;

      &::before {
        content: "";
        z-index: 1;
        position: absolute;
        width: 3px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $color-border-active;
      }

      .navigation__arrow::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    &:hover {
      background-color: $color-background-dark;
    }
  }

  &__icon {
    width: 20px;
    margin-right: $space-md;
  }

  //&__sublist {
  //  background-color: $color-background-dark;
  //  border-top: 1px solid $color-border;
  //
  //  .navigation__link {
  //    position: relative;
  //
  //    &::before {
  //      content: '';
  //      position: absolute;
  //      width: $space-xxs;
  //      height: $space-xxs;
  //      border-radius: 50%;
  //      top: 50%;
  //      transform: translateY(-50%);
  //      left: 48px;
  //      background-color: $color-icon;
  //    }
  //
  //    &--active {
  //      color: $color-text-active;
  //      border-color: transparent;
  //
  //      &::before {
  //        background-color: $color-icon-active;
  //      }
  //    }
  //
  //    &:hover {
  //      color: $color-text-active;
  //
  //      &::before {
  //        background-color: $color-icon-active;
  //      }
  //    }
  //  }
  //}

  //&__arrow {
  //  margin-left: auto;
  //}
}