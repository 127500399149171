.selector {
    
    &__selector {
        border: 1px solid $color-border;
        height: $space-xl;
        font-size: $font-size-md;
        padding: 0 $space-xs;
        background-image: linear-gradient(0deg, $color-background-dark, $color-background);
        border-radius: $border-radius-base;
        color: $color-text-light;
        cursor: pointer;
        width: 100%;

        &--error {
            border-color: $color-danger-light;
            margin-bottom: $space-xxs;
        }

        &--disabled {
            background-color: $color-background-disabled;
            border: 1px solid $color-border-disabled;
            background-image: none;
            cursor: not-allowed;
            color: $color-text-lighter;
        }
    }

    &__error {
        color: $color-danger-light;
        font-size: $font-size-md;
    }
}