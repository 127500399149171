.sortable {

    &__item {
        @include flex(row, center, flex-start);
        border-radius: $border-radius-base;
        border: 1px solid $color-border;
        padding: $space-xs $space-sm;
        background-color: $color-background;
        cursor: pointer;
        z-index: 10000;

        &:not(:last-child) {
            margin-bottom: $space-xs;
        }

        .checkbox {
            margin-left: auto;
        }

        &--active {
            border-color: $color-border-active;
        }
    }

    &__icon {
        color: $color-icon-light;
        transform: rotate(-90deg);
        cursor: row-resize;
        margin-right: $space-sm;
    }

    &__name {
        color: $color-text;
        cursor: pointer;
        text-transform: capitalize;
    }

    &__image {
        max-width: 30px;
        margin-right: $space-sm;
    }
}