.block__container {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    padding: 15px;
    width: 100%;
    position: relative;
}

.block__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 34px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    font-size: 16px;
    width: 100%;
    text-transform: capitalize;
    font-size: 12px;
  }
  
  .block__content {
    margin-top: 16px;
    width: 100%;
  }
  