.selections {
  background-color: $color-background;
  border: 1px solid $color-border;
  border-radius: $border-radius-base;
  width: 100%;
  
  &__header {
    border-bottom: 1px solid $color-border;
    padding: 0 $space-md;
    height: 50px;
    @include flex(row, center, space-between);
  }

  &__title {
    font-size: $font-size-xl;
    font-weight: 700;
  }

  &__search {
    position: relative;
    width: 300px;

    ul {
      position: absolute;
      left: 0;
      top: 100%;
      background-color: $color-background-dark;
      border: 1px solid $color-border-dark;
      z-index: 50;
      width: 100%;
      border-top: none;
      padding: $space-md 0;
      border-radius: 0 0 $border-radius-base $border-radius-base;

      li {
        padding: $space-xxs $space-md;
        cursor: pointer;

        &:hover {
          background-color: $color-primary;
          color: $color-text-inverse;
        }
      }
    }
  }
}

.groups {
  padding: 0 $space-xxl;

  .group {
    @include flex(column, center, center);
    cursor: pointer;
    border: $space-xxs solid transparent;
    margin: 0 $space-xxs;
    padding: $space-xs;
    text-align: center;
    background-color: #fff;

    &:hover {
      border-color: $color-primary;
    }

    &--selected {
      border-color: $color-primary;
    }

    &__image {
      height: 100px;
      @include flex(row, center, center);

      img {
        max-height: 90px;
        max-width: 90px;
      }
    }

    &__name {
      margin-top: $space-xs;
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      color: $color-text;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      min-height: 34px;
      text-transform: uppercase;
    }
  } 

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    height: auto;

    &::before {
      display: none;
    }

    &:hover {
      i {
        color: $color-primary-light;
      }
    }

    i {
      color: $color-primary;
      font-size: 40px;
    }
  }
}

.sections {
  padding: 0 $space-xxl;

  .section {
    @include flex(column, center, center);
    cursor: pointer;
    border: 4px solid transparent;
    margin: 0 $space-xxs;
    padding: $space-xs;
    text-align: center;

    &:hover {
      border-color: $color-primary;
    }

    &--selected {
      border-color: $color-primary;
    }

    &__name {
      margin-top: $space-xs;
      font-weight: 500;
      color: $color-text;
      text-transform: capitalize;
      font-size: $font-size-md;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      min-height: 28px;
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    height: auto;

    &::before {
      display: none;
    }

    &:hover {
      i {
        color: $color-primary-light;
      }
    }

    i {
      color: $color-primary;
      font-size: 40px;
    }
  }
}
