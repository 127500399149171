.table {
    width: 100%;
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: gray;

    &__table {
        width: 100%;
        border-spacing: 0;
    }

    &__thead {
        background-color: #283555;
        overflow: hidden;
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }

    &__th {
        font-weight: 500;
        color: #fff;
        text-transform: capitalize;
        padding: 8px 16px;
        height: 48px;
        text-align: center;

        &:first-child { 
            border-top-left-radius: 3px;
        }
    }

    &__tbody {
        background-color: #fff;
        &--collapsed {
            background-color: #f5f5f5;
        }
    }

    &__td {
        border-bottom: 1px solid #d9d9d9;
        padding: 8px 16px;
        height: 48px;
        text-align: center;
        border-right: none;
        padding: 16px;

        &:first-child {
            border-bottom-left-radius: 3px;
            border-left: 1px solid #d9d9d9;
        }

    }

}



.products-table-image {
    width: 50%;
    max-width: 50%;
}

.alt-btn {
    background: #66747D;
    border: none;
    color: #fff;
    padding: 7px 10px;
    border-radius: 5px;
    outline: none !important;
    cursor: pointer;
}

.alt-btn span {
    display: flex;
    align-items: center;
    position: relative;
}

.alt-btn span .circle {
    margin-left: 5px;
    right: 0;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    background: #fff;
    text-align: center;
    color: #545454;
    font-weight: bold;
    line-height: 18px;
    padding-left: 5px;
}

.sectionTable .sectionName {
    padding: 18px 10px;
    background: #125186;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #32495B;
}

.light .sectionTable .sectionName {
    background: #283555;
    border-bottom: 0px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.section-name__section-image {
    max-width: 30px;
    max-height: 30px;
}

.brand-image {
    max-width: 60px;
}

.section-name__section-label {
    color: #fff;
    margin-left: 10px;
}

.light .sectionTable {
    border: 1px solid #EBEBEB;
    background: #fff;
    margin-bottom: 24px;
    border-radius: 0 0 3px 3px;
}

.sectionTable th:not(:first-child) {
    text-align: center;
}

.sectionTable td:not(:first-child) {
    text-align: center;
}

.sectionTable .table {
    margin-bottom: 0px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 0.9em;
}

.light .sectionTable .table {
    color: #000;
}

.sectionTable .tbody {
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 1.1em;
}

.light .sectionTable .tbody {
    background: transparent;
}

.sectionTable .tr {
    display: flex;
}

.sectionTable .tr .td {
    font-weight: normal;
    padding: 15px 5px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.light .sectionTable .tr .td {
    border-top: 1px solid #D9D9D9;
    text-align: center;
    word-break: break-word;
}

.sectionTable .tr .td:first-child {
    border-left: none;
    flex-direction: column;
}

.sectionTable .tr .td:last-child {
    border-right: none;
}

.full-info-mobile {
    display: none;
}

/* .sectionTable .tr.even {
  background: #212121;
}

.sectionTable .tr.even:hover, .sectionTable .tr.odd:hover {
  background: #484747;
}

.vehiclesList .tr.even:hover, .vehiclesList .tr.odd:hover {
  background: #484747;
}

.sectionTable .tr.odd {

} */
.table-container {
    font-weight: bold;
}

.sectionTable .thead .th {
    width: 100%;
    padding: 18px 0px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
}

.sectionTable .thead {
    display: flex;
}

.sectionTable table {
    width: 100%;
}

.sectionTable table th {
    padding: 18px 20px;
    text-transform: uppercase;
}

/* .sectionTable table td {
    padding: 18px 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #555555;
} */

.show-full-info-btn {
    cursor: pointer;
    background: transparent;
    border: 1px solid #fff;
    padding: 7px;
    color: #fff;
    border-radius: 5px;
    width: 80%;
    text-transform: capitalize;

}

.light .show-full-info-btn {
    background-image: linear-gradient(0deg, rgb(245, 245, 245) 0%, rgb(255, 255, 255) 100%);
    border: 1px solid #DEDEDE;
    color: #808080;
}

.show-full-info-btn i {
    font-size: 1.3em;
    float: right;
    padding-top: 1px;
}

.show-full-info-btn:hover {
    background-image: linear-gradient(0deg, rgb(245, 245, 245) 0%, rgb(255, 255, 255) 100%);
    color: #4D4F5B;
}

.light .show-full-info-btn:hover {
    background-image: linear-gradient(0deg, rgb(245, 245, 245) 0%, rgb(255, 255, 255) 100%);
    color: #4D4F5B;
}

.light .productsTable .is-collapsed .show-full-info-btn {
    background: #808080;
    color: #fff;
}

.light .productsTable .is-collapsed {
    border-top: 0px;
    background: #F0F0F0;
}

.light .productsTable .is-collapsed .tr {
    border-bottom: 1px solid #DCDCDC;

}

.productsTable .is-not-collapsed {
    margin: 0px;
    border: none;
}

.alt-btn.active {
    background: #125186;
}

.light .alt-btn.active {
    background: #3B8BEC;
}

.alt-btn.active span span i {
    color: #125186

}

.radio.active label {
    /* border: 1px solid #fff; */
    border-left: none;
    border-right: none;
    color: #fff;
    background-color: #de1c1c;
}

.lost-sales-modal .radio:hover {
    color: #fff;
    background-color: #de1c1c !important;
}

.lost-sales-modal .radio.active:hover {
    color: #fff;
    background-color: #de1c1c;
}

.products-list-loading {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    background-position: center !important;
}
