.dropzone-container {

  .dropzone {
    border: 1px dashed #333333;
    border-radius: $border-radius-base;
    cursor: pointer;
    
    &__text {
      background-color: $color-background;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 56px 0;

      &__title {
        font-size: $font-size-xxxl;
        font-weight: 600;
        margin-top: $space-sm;
      }

      &__description {
        font-size: $font-size-md;
      }

      &__validation {
        font-size: $font-size-md;
        text-align: center;
        color: $color-text-lighter;
        margin-top: $space-md;
        line-height: 1.5;
      }
    }
  }

  .dropzone__files {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 12px;

    .file {

      img {
        width: 100%;
        height: 80px;
        object-fit: contain;
      }
    }
  }
}